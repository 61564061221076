import { put, takeEvery, call } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchMarkets } from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from './api/callApi'
import { Market } from 'src/interfaces/market'

interface FetchMarketsPayload {
  partnerId: string
}

function* onFetchMarkets(action: AsyncAction<FetchMarketsPayload, Array<Market>>) {
  const { partnerId } = action.payload

  try {
    const { payload }: { payload: Array<Market> } = yield call(
      callSecureApi,
      Endpoints.FetchMarkets,
      {
        apiService: ApiService.Paraworks,
        query: {
          partnerId,
        },
      },
    )

    yield put(FetchMarkets.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch markets'

    yield put(FetchMarkets.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* marketSaga() {
  yield takeEvery(FetchMarkets.type.REQUEST, onFetchMarkets)
}
