import { Typography } from '@mui/material'
import tw from 'twin.macro'

export const ManagersPage = () => {
  return (
    <Typography variant="h2" css={tw`mb-2`}>
      Managers
    </Typography>
  )
}
