import { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { OnboardingStatus } from 'src/constants/onboarding/enums'

interface Props {
  title: string
  subtitle: string
  icon: any
  type: OnboardingStatus
  selected?: boolean
  onClick(type: OnboardingStatus): void
}

const OnboardingTabComponent = ({ title, subtitle, icon, type, selected, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(type)
  }, [type, onClick])

  return (
    <div
      css={[
        tw`flex flex-1 py-2 px-4 rounded-md hover:bg-[#F6F6F6] cursor-pointer`,
        selected && tw`bg-white hover:bg-[#FAFAFA]`,
      ]}
      onClick={handleClick}
    >
      {icon}
      <div css={tw`ml-4`}>
        <Typography
          css={tw`overflow-hidden [display: -webkit-box] [-webkit-line-clamp: 1] [line-clamp: 1] [-webkit-box-orient: vertical]`}
          variant="h6"
        >
          {title}
        </Typography>
        <Typography
          css={tw`overflow-hidden [display: -webkit-box] [-webkit-line-clamp: 1] [line-clamp: 1] [-webkit-box-orient: vertical]`}
          color={colors.SHADES_GREY_400}
          variant="body2"
        >
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}

export const OnboardingTab = memo(OnboardingTabComponent)
