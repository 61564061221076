import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { OnboardingStatus, OnboardingStepStatus } from './enums'
import { gridFormatters, gridValueGetters } from 'src/utils/grid'
import { Chip } from 'src/components/shared/Chip'
import { Onboarding } from 'src/interfaces/onboarding'
import { StepsChip } from 'src/components/onboarding/grid/StepsChip'
import { TableTags } from 'src/components/tags/TableTags'
import { OnboardingsItemActionButton } from 'src/components/onboarding/grid/action/OnboardingsItemActionButton'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { getPartnerDriverCollectDataStepTitle } from 'src/utils/collectedData'

const baseOnboardingsColumns: Array<GridColDef> = [
  {
    field: 'externalId',
    headerName: 'Contractors ID',
    flex: 3,
    sortable: true,
  },
  {
    field: 'name',
    headerName: 'Contractors name',
    flex: 2,
    sortable: true,
  },
  {
    field: 'phone',
    headerName: 'Phone number',
    flex: 2,
    sortable: true,
  },
  {
    field: 'tags',
    headerName: 'Tag(s)',
    flex: 3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Onboarding>) =>
      params.row?.tags.filter((item) => !!item).length ? (
        <TableTags tags={params.row?.tags.filter((item) => !!item) || []} />
      ) : (
        <span>-</span>
      ),
  },

  // {
  //   field: 'actions',
  //   headerName: 'Actions',
  //   flex: 1,
  //   sortable: true,
  //   // renderCell: (params) => <OnboardingsItemActionButton selectedOnboarding={params.row} />,
  // },
]

const invitedContractorsTable = [
  ...baseOnboardingsColumns,
  {
    field: 'createdAt',
    headerName: 'Invited at',
    flex: 2,
    sortable: true,
    type: 'date',
    valueGetter: gridValueGetters.parseTimestamp,
    valueFormatter: gridFormatters.formatShortDate,
  },
  {
    field: 'completed_steps',
    headerName: 'Steps completed',
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams<Onboarding>) => (
      <Chip
        text={`${
          params.row.partnerDriverCollectedData.filter(
            (item) => item.status !== OnboardingStepStatus.Created,
          ).length
        } of ${params.row.partnerDriverCollectedData.length}`}
      />
    ),
  },
]

export const onboardingColumns = {
  [OnboardingStatus.Created]: invitedContractorsTable,
  [OnboardingStatus.PartiallyCompleted]: invitedContractorsTable,
  [OnboardingStatus.WaitingForApproval]: [
    ...baseOnboardingsColumns,
    {
      field: 'steps_to_review',
      headerName: 'Documents to review',
      flex: 3,
      sortable: true,
      renderCell: (params: GridRenderCellParams<Onboarding>) => (
        <StepsChip
          titles={params.row.partnerDriverCollectedData
            .filter((item) => item.status === OnboardingStepStatus.WaitingForApproval)
            .map((item) => getPartnerDriverCollectDataStepTitle(item))}
        />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Invited at',
      flex: 2,
      sortable: true,
      type: 'date',
      valueGetter: gridValueGetters.parseTimestamp,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [OnboardingStatus.Completed]: [
    ...baseOnboardingsColumns,
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 1.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams<Onboarding>) => (
        <Chip
          className={params?.value ? tw`bg-[#04C523]` : tw`bg-[#E33914]`}
          text={params?.value ? 'Active' : 'Inactive'}
          textColor={colors.GRAY_WHITE}
        />
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 3,
      sortable: true,
    },
    {
      field: 'onboardedAt',
      headerName: 'Date onboarded',
      flex: 2,
      sortable: true,
      type: 'date',
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<Onboarding>) => (
        <OnboardingsItemActionButton onboarding={params.row} />
      ),
    },
  ],
  [OnboardingStatus.Failed]: [
    ...baseOnboardingsColumns,
    {
      field: 'failed_steps',
      headerName: 'Failed on stage',
      flex: 3,
      sortable: true,
      renderCell: (params: GridRenderCellParams<Onboarding>) => (
        <StepsChip
          titles={params.row.partnerDriverCollectedData
            .filter((item) => item.status === OnboardingStepStatus.Failed)
            .map((item) => getPartnerDriverCollectDataStepTitle(item))}
        />
      ),
    },
  ],
}

export const onboardingInitialSortFields = {
  [OnboardingStatus.Created]: 'createdAt',
  [OnboardingStatus.WaitingForApproval]: 'createdAt',
  [OnboardingStatus.Completed]: 'onboardedAt',
  [OnboardingStatus.Failed]: undefined,
  [OnboardingStatus.PartiallyCompleted]: undefined,
}

export const onboardingPageSizeOptions = [5, 10, 20, 50, 100]

export const ONBOARDINGS_COLUMNS_KEY = 'onboardingsColumns'
