import { ReactNode } from 'react'
import tw from 'twin.macro'

interface Props {
  children: ReactNode
}

export const PublicWrapper = ({ children }: Props) => {
  return (
    <div
      css={tw`flex h-full justify-center items-center [background: radial-gradient(93.09% 93.09% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, #000 100%)]`}
    >
      {children}
    </div>
  )
}
