import React, { memo, useCallback } from 'react'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { ImportMinor } from '@shopify/polaris-icons'
import { Typography } from '@mui/material'
import tw from 'twin.macro'

const ExportTableButtonComponent = () => {
  const apiRef = useGridApiContext()

  const handleExport = useCallback(() => {
    apiRef.current.exportDataAsCsv()
  }, [apiRef])

  return (
    <div
      css={tw`p-1 rounded bg-[#EDEDED] h-[28px] hover:bg-[#DAD9E0] active:bg-[#B4AFC0] cursor-pointer flex items-center w-[114px] justify-center`}
      onClick={handleExport}
    >
      <Typography css={tw`mr-1`} variant="subtitle1">
        Export table
      </Typography>
      <ImportMinor width={16} height={16} />
    </div>
  )
}

export const ExportTableButton = memo(ExportTableButtonComponent)
