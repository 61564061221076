import React, { ReactNode } from 'react'
import tw from 'twin.macro'
import LogoWhite from 'src/assets/images/gigsafe-logo-white.svg'
import { SideMenu } from './SideMenu'
import { MarketSelectButton } from '../header/MarketSelectButton'
import { PartnersProfileButton } from '../header/PartnersProfileButton'
import { ActivitiesButton } from '../header/ActivitiesButton'

interface Props {
  children: ReactNode
}

export const SecureWrapper = ({ children }: Props) => {
  return (
    <div css={tw`h-full w-full flex flex-col`}>
      <div
        css={tw`bg-[#300258] w-full h-[60px] p-4 shadow top-0 flex justify-between items-center z-50`}
      >
        <img src={LogoWhite} css={tw`h-[28px]`} />
        <div>
          <ActivitiesButton />
          <MarketSelectButton />
          <PartnersProfileButton />
        </div>
      </div>
      <div css={tw`flex flex-1 bg-[#F6F6F6] flex-row`}>
        <SideMenu />
        <div css={tw`flex flex-1 py-6 px-4 flex-col`}>{children}</div>
      </div>
    </div>
  )
}
