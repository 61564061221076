export enum OnboardingStatus {
  Created = 'created',
  Completed = 'completed',
  WaitingForApproval = 'waiting_for_approval',
  Failed = 'failed',
  PartiallyCompleted = 'partially_completed',
}

export enum OnboardingStepType {
  DriverLicense = 'driver_license',
  VehicleAndInsurance = 'vehicle_and_insurance',
  InsuranceAmountDeclaration = 'insurance_amount_declaration',
  VehicleRegistration = 'vehicle_registration',
  VaccinationInformation = 'vaccination_information',
  BackgroundCheck = 'background_check',
  PaymentMethod = 'payment_method',
  InsuranceBeneficiary = 'insurance_beneficiary',
  SignContract = 'sign_contract',
  Training = 'training',
  StitchPayment = 'stitch_payment',
  Questionnaire = 'questionnaire',
  MvrUpload = 'mvr_upload',
  InPersonMeeting = 'in_person_meeting',
  Form = 'form',
}

export enum OnboardingStepStatus {
  Created = 'created',
  InProgress = 'in_progress',
  WaitingForInternalApproval = 'waiting_for_internal_approval',
  WaitingForApproval = 'waiting_for_approval',
  Completed = 'completed',
  Failed = 'failed', // Applies only for BG check
}
