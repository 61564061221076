import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { userReducer } from './user'
import { inviteReducer } from './invite'
import { marketReducer } from './market'
import { tagReducer } from './tag'
import { onboardingReducer } from './onboarding'
import { contractorReducer } from './contractor'
import { activitiesReducer } from './activities'

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  invite: inviteReducer,
  tag: tagReducer,
  market: marketReducer,
  contractor: contractorReducer,
  onboarding: onboardingReducer,
  activities: activitiesReducer,
})
