import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import tw from 'twin.macro'
import { OnboardingsGrid } from './OnboardingsGrid'
import { useDispatch, useSelector } from 'react-redux'
import { Onboarding } from 'src/interfaces/onboarding'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  FetchOnboarding,
  FetchOnboardings,
  FetchOnboardingsCounts,
} from 'src/constants/actionTypes'
import {
  onboardingPageSizeOptions,
  onboardingInitialSortFields,
} from 'src/constants/onboarding/onboardingGrid'
import { IOnboardingsContext, OnboardingsContext } from './Onboardings.context'
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { getPartnerId } from 'src/selectors/user'
import { getSelectedMarket } from 'src/selectors/market'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { OnboardingsHeader } from './OnboardingsHeader'
import { Typography } from '@mui/material'
import { onboardingStatusTitle } from 'src/constants/onboarding/constants'
import { ContractorDetailsModal } from 'src/components/contractor-details/ContractorDetailsModal'
import { OnboardingTabs } from '../tabs/OnboardingTabs'
import { InviteWorkersButton } from '../invite-workers/InviteWorkersButton'
import { ManageTagsButton } from 'src/components/tags/ManageTagsButton'
import { OnboardingsItemActionMenu } from './action/OnboardingsItemActionMenu'
import { ContractorDetails } from 'src/interfaces/contractor'
import { handleApiError } from 'src/utils/errorHandlers'
import { useLocation } from 'react-router-dom'

interface FetchOnboardingsParams {
  limit?: number
  offset?: number
  searchQuery?: string
  tagsFilter?: Array<string>
}

const OnboardingsComponent = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()

  const partnerId = useSelector(getPartnerId)
  const selectedMarket = useSelector(getSelectedMarket)

  const { paginationModel, limit, offset, setPaginationModel } =
    useGridPagination(onboardingPageSizeOptions)

  const selectedTabRef = useRef<OnboardingStatus | null>(null)

  const [selectedTab, setSelectedTab] = useState(OnboardingStatus.Created)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [onboardings, setOnboardings] = useState<Array<Onboarding>>([])
  const [totalOnboardings, setTotalOnboardings] = useState<number>(0)
  const [actionAnchor, setActionAnchor] = useState<null | HTMLElement>(null)
  const [onboardingToAction, setOnboardingToAction] = useState<Onboarding | null>(null)
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedOnboardingId, setSelectedOnboardingId] = useState<string | null>(null)
  const [tagsFilter, setTagsFilter] = useState<Array<string>>([])
  const [isManageTagsOpen, setIsManageTagsOpen] = useState(false)
  const [contractorDetails, setContractorDetails] = useState<ContractorDetails | null>(null)

  const handleSelectTab = useCallback((tab: OnboardingStatus) => {
    selectedTabRef.current = tab
    setSelectedTab(tab)
  }, [])

  useEffect(() => {
    if (!state?.selectedTab || state?.selectedTab === selectedTabRef.current) {
      return
    }

    handleSelectTab(state.selectedTab)
  }, [state?.selectedTab])

  const fetchOnboardings = useCallback(
    async ({ limit, offset, searchQuery, tagsFilter }: FetchOnboardingsParams) => {
      if (!partnerId) {
        return
      }

      setIsLoading(true)

      try {
        const params: any = {
          partnerId,
          onboardingStatus: selectedTab,
          limit,
          offset,
        }

        if (searchQuery) {
          params.partnerDriverSearch = searchQuery
        }

        if (selectedMarket?.id) {
          params.partnerMarketId = selectedMarket?.id
        }

        if (tagsFilter?.length) {
          params.tags = tagsFilter
        }

        const payload: Array<Onboarding> = await createAsyncAction(
          dispatch,
          FetchOnboardings.request(params),
        )

        setOnboardings(payload)
        setTotalOnboardings(payload.length)
      } catch (err: unknown) {
        handleApiError(err)
      }

      setIsLoading(false)
    },
    [partnerId, selectedMarket?.id, selectedTab],
  )

  const fetchOnboardingCounts = useCallback(() => {
    if (!partnerId) {
      return
    }

    const params: any = { partnerId }

    if (selectedMarket?.id) {
      params.partnerMarketId = selectedMarket?.id
    }

    dispatch(FetchOnboardingsCounts.request(params))
  }, [partnerId, selectedMarket?.id])

  const refreshOnboardings = useCallback(async () => {
    await fetchOnboardings({ limit, offset, searchQuery })
    fetchOnboardingCounts()
  }, [limit, offset, searchQuery, fetchOnboardings, fetchOnboardingCounts])

  useEffect(() => {
    fetchOnboardings({
      limit,
      offset,
      searchQuery,
      tagsFilter,
    })
    fetchOnboardingCounts()
  }, [limit, offset, searchQuery, partnerId, selectedMarket?.id, selectedTab, tagsFilter])

  const handleChangePagination = useCallback((paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
  }, [])

  const openOnboardingsItemAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, onboardingToEdit: Onboarding) => {
      setActionAnchor(event.currentTarget)
      setOnboardingToAction(onboardingToEdit)
    },
    [],
  )

  const closeOnboardingsItemAction = useCallback(() => {
    setActionAnchor(null)
    setOnboardingToAction(null)
  }, [])

  const selectedOnboardings = useMemo<Array<Onboarding>>(() => {
    return selectedRows.reduce<Array<Onboarding>>((arr, selectedRowId) => {
      const onboarding = onboardings.find((item) => item.id === selectedRowId)

      if (onboarding) {
        arr.push(onboarding)
      }

      return arr
    }, [])
  }, [onboardings, selectedRows])

  const openManageTags = useCallback(() => {
    setIsManageTagsOpen(true)
  }, [])

  const closeManageTags = useCallback(() => {
    setIsManageTagsOpen(false)
  }, [])

  const onboardingsContextValue = useMemo<IOnboardingsContext>(() => {
    return {
      selectedRows,
      setSelectedRows,

      selectedOnboardings,

      searchQuery,
      setSearchQuery,

      tagsFilter,
      setTagsFilter,

      refreshOnboardings,

      actionAnchor,
      onboardingToAction,
      openOnboardingsItemAction,
      closeOnboardingsItemAction,

      isManageTagsOpen,
      openManageTags,
      closeManageTags,
    }
  }, [
    selectedRows,
    setSelectedRows,

    selectedOnboardings,

    searchQuery,
    setSearchQuery,

    tagsFilter,
    setTagsFilter,

    refreshOnboardings,

    actionAnchor,
    onboardingToAction,
    openOnboardingsItemAction,
    closeOnboardingsItemAction,

    isManageTagsOpen,
    openManageTags,
    closeManageTags,
  ])

  const DataGridTitle = useMemo(
    () => () =>
      (
        <div css={tw`w-full`}>
          <Typography css={tw`ml-4 my-3`} variant="h5">
            {onboardingStatusTitle[selectedTab]}
          </Typography>
          <div css={tw`w-full h-[1px] bg-[#EDEDED]`} />
        </div>
      ),
    [selectedTab],
  )

  const handleRowClick = useCallback(({ row }: { row: Onboarding }) => {
    setSelectedOnboardingId(row.onboardingId)
  }, [])

  const handleCloseDetails = useCallback(() => {
    setSelectedOnboardingId(null)
  }, [])

  const fetchContractorDetails = useCallback(async (onboardingId: string) => {
    try {
      const contractor: ContractorDetails = await createAsyncAction(
        dispatch,
        FetchOnboarding.request({ onboardingId }),
      )

      setContractorDetails(contractor)
    } catch (err) {
      handleApiError(err)
    }
  }, [])

  useEffect(() => {
    if (!selectedOnboardingId) {
      setContractorDetails(null)
    } else {
      fetchContractorDetails(selectedOnboardingId)
    }
  }, [selectedOnboardingId])

  const refreshOnboardingDetails = useCallback(async () => {
    if (selectedOnboardingId) {
      await fetchContractorDetails(selectedOnboardingId)
    }
    refreshOnboardings()
  }, [selectedOnboardingId, refreshOnboardings])

  return (
    <OnboardingsContext.Provider value={onboardingsContextValue}>
      <div css={tw`flex flex-row items-center justify-between mb-6`}>
        <Typography variant="h2" css={tw`mb-2`}>
          Onboarding
        </Typography>
        <div css={tw`flex`}>
          <ManageTagsButton
            isManageTagsOpen={isManageTagsOpen}
            openManageTags={openManageTags}
            closeManageTags={closeManageTags}
          />
          <InviteWorkersButton />
        </div>
      </div>
      <OnboardingTabs selectedTab={selectedTab} onClick={handleSelectTab} />
      <OnboardingsHeader />
      <OnboardingsGrid
        onboardings={onboardings}
        isLoading={isLoading}
        selectedOnboardingStatus={selectedTab}
        paginationModel={paginationModel}
        pageSizeOptions={onboardingPageSizeOptions}
        rowCount={totalOnboardings}
        rowSelectionModel={selectedRows}
        initialSortField={onboardingInitialSortFields[selectedTab]}
        onPaginationModelChange={handleChangePagination}
        onRowSelectionModelChange={setSelectedRows}
        onRowClick={handleRowClick}
        DataGridTitle={DataGridTitle}
      />
      <OnboardingsItemActionMenu />
      {contractorDetails && (
        <ContractorDetailsModal
          contractorDetails={contractorDetails}
          flow="onboardings"
          onClose={handleCloseDetails}
          onRefresh={refreshOnboardingDetails}
        />
      )}
    </OnboardingsContext.Provider>
  )
}

export const Onboardings = memo(OnboardingsComponent)
