import { memo, useEffect } from 'react'
import { useFetchUserAndData } from './hooks/useFetchUserAndData'
import { useSelector } from 'react-redux'
import { getAuthToken } from './selectors/auth'

export const SecureHooksContainer = memo(() => {
  const { fetchUserAndMarkets } = useFetchUserAndData()

  const authToken = useSelector(getAuthToken)

  useEffect(() => {
    if (!authToken) {
      return
    }

    fetchUserAndMarkets()
  }, [authToken, fetchUserAndMarkets])

  return null
})
