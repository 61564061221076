import { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'

interface Props {
  onUnreject(): void
}

const UnrejectContractorComponent = ({ onUnreject }: Props) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  const handleSubmit = useCallback(() => {
    onUnreject()
    setIsConfirmOpen(false)
  }, [onUnreject])

  return (
    <div css={tw`w-full bg-white px-4 py-2`}>
      <Button className={tw`px-8`} size="small" color="green" onClick={handleOpenConfirm}>
        Unreject contractor
      </Button>
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title="Unreject contractor?"
        text="Are you sure you want to unreject contractor?"
        submitLabel="Unreject"
        color="green"
        onClose={handleCloseConfirm}
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export const UnrejectContractor = memo(UnrejectContractorComponent)
