export enum StitchStatus {
  Created = 'created',
}

export enum ContractorStatus {
  Active = 'active',
  Deactivated = 'deactivated',
  ExpiresSoon = 'expires_soon',
}

export enum ContractorState {
  Active = 'active',
  Deactivated = 'deactivated',
  Recollection = 'recollection',
}

export enum ContractorOnboardingStatus {
  Created = 'created',
  Completed = 'completed',
  WaitingForApproval = 'waiting_for_approval',
  Failed = 'failed',
  PartiallyCompleted = 'partially_completed',
}
