import { Typography } from '@mui/material'
import { CircleAlertMajor, MobileCancelMajor } from '@shopify/polaris-icons'
import { CustomContentProps, useSnackbar } from 'notistack'
import { forwardRef, useCallback } from 'react'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props extends CustomContentProps {
  detailsPayload?: any
  onDetailsClick?(payload: any): void
}

export const SnackbarErrorWithDetails = forwardRef<HTMLDivElement, Props>(
  ({ style, id, message, detailsPayload, onDetailsClick }, ref) => {
    const { closeSnackbar } = useSnackbar()

    const handleClose = useCallback(() => {
      closeSnackbar(id)
    }, [id, closeSnackbar])

    const handleDetailsClick = useCallback(() => {
      if (!onDetailsClick) {
        return
      }

      onDetailsClick(detailsPayload)
      handleClose()
    }, [detailsPayload, onDetailsClick, handleClose])

    return (
      <div
        ref={ref}
        style={style}
        css={tw`py-2 px-3 flex items-center min-w-[340px] bg-[#000000] [border-radius: 8px]`}
      >
        <div css={tw`mr-3`}>
          <CircleAlertMajor width={20} height={20} fill={colors.COLORS_RED_500} />
        </div>
        <div css={tw`flex-1 mr-3`}>
          <div>
            <Typography variant="body1" color={colors.BASICS_WHITE}>
              {message}
            </Typography>
          </div>
          <div>
            <Typography
              css={tw`underline cursor-pointer`}
              variant="body1"
              color={colors.BASICS_WHITE}
              onClick={handleDetailsClick}
            >
              Tap to learn more
            </Typography>
          </div>
        </div>
        <div onClick={handleClose}>
          <MobileCancelMajor
            css={tw`cursor-pointer`}
            width={20}
            height={20}
            fill={colors.BASICS_WHITE}
          />
        </div>
      </div>
    )
  },
)
