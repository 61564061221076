import { Logout, FetchOnboardingsCounts, FetchOnboardingTemplates } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { OnboardingState } from './types'

const getInitialState = (): OnboardingState => ({
  onboardingCounts: null,
  onboardingTemplates: [],
})

export const onboardingReducer = (
  state: OnboardingState = getInitialState(),
  action: Action<any>,
): OnboardingState => {
  switch (action.type) {
    case FetchOnboardingsCounts.type.SUCCESS:
      return {
        ...state,
        onboardingCounts: action.payload,
      }
    case FetchOnboardingTemplates.type.SUCCESS:
      return {
        ...state,
        onboardingTemplates: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
