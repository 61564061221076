import React, { memo, MouseEvent, useCallback, useContext, useMemo, useState } from 'react'
import { ActionButton } from 'src/components/shared/ActionButton'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { CirclePlusMinor, CirclePlusOutlineMinor, ExportMinor } from '@shopify/polaris-icons'
import { UploadPaymentsCSVModal } from './UploadPaymentsCSVModal'
import { PaymentsContext } from '../grid/Payments.context'
import { AddSinglePaymentModal } from './AddSinglePaymentModal'
import tw from 'twin.macro'

const AddPaymentsActionsButtonComponent = () => {
  const { refreshPayments } = useContext(PaymentsContext)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isUploadPaymentsModalOpen, setIsUploadPaymentsModalOpen] = useState(false)
  const [isAddSinglePaymentModalOpen, setIsAddSinglePaymentModalOpen] = useState(false)

  const handleUploadPaymentsCloseModal = useCallback(() => {
    setIsUploadPaymentsModalOpen(false)
  }, [])

  const handleAddSinglePaymentCloseModal = useCallback(() => {
    setIsAddSinglePaymentModalOpen(false)
  }, [])

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    return [
      {
        label: 'Upload CSV file',
        icon: <ExportMinor width={20} height={20} />,
        onClick: async () => {
          setAnchorEl(null)

          setIsUploadPaymentsModalOpen(true)
        },
      },
      {
        label: 'Add single payment',
        icon: <CirclePlusOutlineMinor width={20} height={20} />,
        onClick: async () => {
          setAnchorEl(null)

          setIsAddSinglePaymentModalOpen(true)
        },
      },
    ]
  }, [])

  const handleSinglePayment = useCallback(() => {
    refreshPayments()
    setIsAddSinglePaymentModalOpen(false)
  }, [refreshPayments])

  if (!actionItems.length) {
    return null
  }

  return (
    <>
      <ActionButton
        selected={!!anchorEl}
        color="white"
        text="Add payments"
        icon={<CirclePlusMinor width={20} height={20} />}
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        itemClassName={tw`min-w-[210px]`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      />
      <UploadPaymentsCSVModal
        isOpen={isUploadPaymentsModalOpen}
        onClose={handleUploadPaymentsCloseModal}
        onSubmit={refreshPayments}
      />
      <AddSinglePaymentModal
        isOpen={isAddSinglePaymentModalOpen}
        onClose={handleAddSinglePaymentCloseModal}
        onSubmit={handleSinglePayment}
      />
    </>
  )
}

export const AddPaymentsActionsButton = memo(AddPaymentsActionsButtonComponent)
