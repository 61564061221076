import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { createContext, MouseEvent } from 'react'
import { PaymentStatus } from 'src/constants/payment/enums'
import { Payment } from 'src/interfaces/payment'

export interface IPaymentsContext {
  selectedRows: GridRowSelectionModel
  setSelectedRows(rowSelectionModel: GridRowSelectionModel): void

  selectedPayments: Array<Payment>

  searchQuery: string
  setSearchQuery(value: string): void

  statusFilter: Array<PaymentStatus>
  setStatusFilter(statusFilter: Array<PaymentStatus>): void

  refreshPayments(): Promise<void>

  // edit action
  actionAnchor: null | HTMLElement
  paymentToAction: Payment | null
  openPaymentsItemAction(event: MouseEvent<HTMLButtonElement>, payment: Payment): void
  closePaymentsItemAction(): void
  clearPaymentsItemAction(): void
}

export const PaymentsContext = createContext<IPaymentsContext>({
  selectedRows: [],
  setSelectedRows: () => {},

  selectedPayments: [],

  searchQuery: '',
  setSearchQuery: () => {},

  statusFilter: [],
  setStatusFilter: () => {},

  refreshPayments: async () => {},

  actionAnchor: null,
  paymentToAction: null,
  openPaymentsItemAction: () => {},
  closePaymentsItemAction: () => {},
  clearPaymentsItemAction: () => {},
})
