import Menu from '@mui/material/Menu'
import React, { memo, useCallback, useContext, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material'
import { ProductsMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { ActionButton } from 'src/components/shared/ActionButton'
import { PaymentsContext } from './Payments.context'
import { PaymentStatus } from 'src/constants/payment/enums'
import { PaymentStatusChip } from './PaymentStatusChip'

const statuses = [
  {
    value: PaymentStatus.Paid,
    component: <PaymentStatusChip status={PaymentStatus.Paid} />,
  },
  {
    value: PaymentStatus.NotPaid,
    component: <PaymentStatusChip status={PaymentStatus.NotPaid} />,
  },
  {
    value: PaymentStatus.Pending,
    component: <PaymentStatusChip status={PaymentStatus.Pending} />,
  },
  {
    value: PaymentStatus.Failed,
    component: <PaymentStatusChip status={PaymentStatus.Failed} />,
  },
]

const PaymentStatusSelectComponent = () => {
  const { statusFilter, setStatusFilter } = useContext(PaymentsContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleToggle = useCallback(
    (value: PaymentStatus) => {
      const currentIndex = statusFilter.indexOf(value)

      if (currentIndex === -1) {
        setStatusFilter([...statusFilter, value])
      } else {
        setStatusFilter([
          ...statusFilter.slice(0, currentIndex),
          ...statusFilter.slice(currentIndex + 1),
        ])
      }
    },
    [statusFilter],
  )

  const handleClear = useCallback(() => {
    setStatusFilter([])
  }, [])

  const getItemChecked = useCallback(
    (status: PaymentStatus) => {
      return statusFilter.includes(status)
    },
    [statusFilter],
  )

  return (
    <>
      <ActionButton
        className={tw`mr-4 px-4`}
        selected={!!anchorEl}
        color="white"
        text="Payment Status"
        icon={
          statusFilter?.length ? (
            <div css={tw`w-5 h-5 items-center justify-center flex rounded bg-[#7F00FF]`}>
              <Typography variant="overline" color="white">
                {statusFilter.length}
              </Typography>
            </div>
          ) : (
            <ProductsMinor width={20} />
          )
        }
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        css={tw`mt-2 rounded-xl`}
      >
        <div css={tw`px-4 py-2 flex items-center justify-between`}>
          <Typography variant="h5">Select:</Typography>
          {statusFilter?.length ? (
            <div css={tw`cursor-pointer hover:opacity-50`} onClick={handleClear}>
              <Typography variant="subtitle1" color={colors.PRIMARY_PURPLE_500}>
                Clear all
              </Typography>
            </div>
          ) : null}
        </div>
        <List>
          {statuses.map(({ value, component }) => {
            const labelId = `checkbox-list-label-${value}`

            return (
              <ListItem css={tw`w-[196px]`} key={value} disablePadding>
                <ListItemButton role={undefined} onClick={() => handleToggle(value)} dense>
                  <ListItemIcon css={tw`min-w-0 mr-2`}>
                    <Checkbox
                      edge="start"
                      checked={getItemChecked(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  {component}
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Menu>
    </>
  )
}

export const PaymentStatusSelect = memo(PaymentStatusSelectComponent)
