class AuthService {
  private STORAGE_AUTH_TOKEN_KEY = 'dashboardAuthToken'
  private STORAGE_REFRESH_TOKEN_KEY = 'dashboardRefreshToken'

  public setStoredAuthToken(authToken: string | null) {
    if (!authToken) {
      localStorage.removeItem(this.STORAGE_AUTH_TOKEN_KEY)
      return
    }

    localStorage.setItem(this.STORAGE_AUTH_TOKEN_KEY, authToken)
  }

  public getStoredAuthToken(): string | null {
    const authToken = localStorage.getItem(this.STORAGE_AUTH_TOKEN_KEY)

    return authToken
  }

  public setStoredRefreshToken(refreshToken: string | null) {
    if (!refreshToken) {
      localStorage.removeItem(this.STORAGE_REFRESH_TOKEN_KEY)
      return
    }

    localStorage.setItem(this.STORAGE_REFRESH_TOKEN_KEY, refreshToken)
  }

  public getStoreRefreshToken(): string | null {
    const refreshToken = localStorage.getItem(this.STORAGE_REFRESH_TOKEN_KEY)

    return refreshToken
  }
}

export const authService = new AuthService()
