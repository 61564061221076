import { memo, useCallback, useEffect, useState } from 'react'
import { ActivityItem } from 'src/interfaces/activities'
import { ActivitiesListItem } from './ActivitiesListItem'
import { activitiesService } from 'src/services/ActivitiesService'
import tw from 'twin.macro'
import { SetActivityDetails } from 'src/constants/actionTypes'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { getDashboardsUpdatesNotViewed } from 'src/selectors/activities'
import { DashboardsUpdatesType } from 'src/constants/activities'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { PaymentStatus } from 'src/constants/payment/enums'
import { BehaviorMajor, NotificationMajor } from '@shopify/polaris-icons'
import { ContractorState } from 'src/constants/contractor/enums'
import { OnboardingStatus } from 'src/constants/onboarding/enums'

interface Props {
  onItemClick(): void
}

const ActivitiesListComponent = ({ onItemClick }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const disabledContractorsUpdates = useSelector(
    getDashboardsUpdatesNotViewed(DashboardsUpdatesType.DisabledContractors),
  )
  const failedPaymentsUpdates = useSelector(
    getDashboardsUpdatesNotViewed(DashboardsUpdatesType.FailedPayments),
  )
  const waitingForApprovalUpdates = useSelector(
    getDashboardsUpdatesNotViewed(DashboardsUpdatesType.WaitingForApproval),
  )

  const [activities, setActivities] = useState<ActivityItem[]>([])

  useEffect(() => {
    setActivities(activitiesService.getActivities())
  }, [])

  const handleItemClick = useCallback(
    (item: ActivityItem) => {
      dispatch(SetActivityDetails.success(item))
      onItemClick()
    },
    [onItemClick],
  )

  const handleOpenPayments = useCallback(() => {
    navigate(ROUTES.PAYMENTS_URL, {
      state: { statusFilter: [PaymentStatus.Failed] },
      replace: true,
    })
    onItemClick()
  }, [onItemClick])

  const handleOpenDeactivatedContractors = useCallback(() => {
    navigate(ROUTES.CONTRACTORS_URL, {
      state: { selectedTab: null },
      replace: true,
    })
    setTimeout(() => {
      navigate(ROUTES.CONTRACTORS_URL, {
        state: { selectedTab: ContractorState.Deactivated },
        replace: true,
      })
    }, 300)
    onItemClick()
  }, [onItemClick])

  const handleOpenWaitApprovalOnboardings = useCallback(() => {
    navigate(ROUTES.ONBOARDING_URL, {
      state: { selectedTab: null },
      replace: true,
    })
    setTimeout(() => {
      navigate(ROUTES.ONBOARDING_URL, {
        state: { selectedTab: OnboardingStatus.WaitingForApproval },
        replace: true,
      })
    }, 300)
    onItemClick()
  }, [onItemClick])

  if (
    !disabledContractorsUpdates.length &&
    !failedPaymentsUpdates.length &&
    !waitingForApprovalUpdates.length &&
    !activities.length
  ) {
    return (
      <div css={tw`p-4 w-[460px]`}>
        <Typography
          css={tw`text-center`}
          variant="body2"
          color={colors.SHADES_GREY_400}
          fontWeight={500}
        >
          No activities found
        </Typography>
      </div>
    )
  }

  return (
    <div css={tw`w-[460px]`}>
      {!!failedPaymentsUpdates.length && (
        <ActivitiesListItem
          key={DashboardsUpdatesType.FailedPayments}
          item={{
            id: DashboardsUpdatesType.FailedPayments,
            title: 'Alert',
            text: `${failedPaymentsUpdates.length} payments has been failed.`,
            icon: <NotificationMajor width={20} height={20} />,
            isNotification: true,
            createdAt: Date.now(),
          }}
          onClick={handleOpenPayments}
        />
      )}
      {!!disabledContractorsUpdates.length && (
        <ActivitiesListItem
          key={DashboardsUpdatesType.DisabledContractors}
          item={{
            id: DashboardsUpdatesType.DisabledContractors,
            title: 'Alert',
            text: `${disabledContractorsUpdates.length} drivers have expired documents.`,
            icon: <NotificationMajor width={20} height={20} />,
            isNotification: true,
            createdAt: Date.now(),
          }}
          onClick={handleOpenDeactivatedContractors}
        />
      )}
      {!!waitingForApprovalUpdates.length && (
        <ActivitiesListItem
          key={DashboardsUpdatesType.WaitingForApproval}
          item={{
            id: DashboardsUpdatesType.WaitingForApproval,
            title: 'Action Required',
            text: `${waitingForApprovalUpdates.length} drivers are pending your approval.`,
            icon: <BehaviorMajor width={20} height={20} />,
            isNotification: true,
            createdAt: Date.now(),
          }}
          onClick={handleOpenWaitApprovalOnboardings}
        />
      )}
      {activities.map((activity) => (
        <ActivitiesListItem key={activity.id} item={activity} onClick={handleItemClick} />
      ))}
    </div>
  )
}

export const ActivitiesList = memo(ActivitiesListComponent)
