import React, { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import Lottie from 'lottie-react'
import shieldAnimation from '../assets/animations/shield.json'

const LoadingPageComponent = () => {
  return (
    <div
      css={tw`w-full h-full flex flex-1 flex-col [background: radial-gradient(93.09% 93.09% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, #000 100%)] items-center justify-center`}
    >
      <Lottie animationData={shieldAnimation} loop={true} />
      <Typography color={colors.GRAY_WHITE} css={tw`font-bold mt-4 [font-size: 12px]`}>
        LOADING...
      </Typography>
    </div>
  )
}

export const LoadingPage = memo(LoadingPageComponent)
