export enum UseRole {
  SuperAdmin = 'super_admin',
  Owner = 'owner',
  Admin = 'admin',
  Manager = 'manager',
}

export enum UserPermission {
  DocumentsEdit = 'documents_edit',
  DocumentsView = 'documents_view',
  DocumentsInternal = 'documents_internal',
  FeatureFlagsEdit = 'feature_flags_edit',
  FeatureFlagsView = 'feature_flags_view',
  FeatureFlagsDelete = 'feature_flags_delete',
  FeatureFlagsCreate = 'feature_flags_create',
  MarketEdit = 'market_edit',
  MarketView = 'market_view',
  MarketDelete = 'market_delete',
  MarketCreate = 'market_create',
  OnboardingEdit = 'onboarding_edit',
  OnboardingView = 'onboarding_view',
  OnboardingCreate = 'onboarding_create',
  OnboardingRecollect = 'onboarding_recollect',
  OnboardingInternal = 'onboarding_internal',
  OnboardingDownloadCsv = 'onboarding_download_csv',
  PartnerEdit = 'partner_edit',
  PartnerView = 'partner_view',
  PartnerGigsafeConfigEdit = 'partner_gigsafe_config_edit',
  PartnerGigsafeConfigView = 'partner_gigsafe_config_view',
  PartnerProductsEdit = 'partner_products_edit',
  PartnerProductsView = 'partner_products_view',
  PartnerProductsDelete = 'partner_products_delete',
  PartnerProductsCreate = 'partner_products_create',
  PartnerDriversImport = 'partner_drivers_import',
  PartnerDriversEdit = 'partner_drivers_edit',
  PartnerDriversView = 'partner_drivers_view',
  PaymentsEdit = 'payments_edit',
  PaymentsView = 'payments_view',
  PaymentsDelete = 'payments_delete',
  PaymentsCreate = 'payments_create',
  PaymentsProcess = 'payments_process',
  PaymentsDownloadInvoices = 'payments_download_invoices',
  PaymentsCreateBuyer = 'payments_create_buyer',
  TemplatesEdit = 'templates_edit',
  TemplatesView = 'templates_view',
  TemplatesDelete = 'templates_delete',
  TemplatesCreate = 'templates_create',
  UsersEdit = 'users_edit',
  UsersView = 'users_view',
  UsersCreate = 'users_create',
  LoginAs = 'login_as',
  InvitesView = 'invites_view',
  InvitesCreate = 'invites_create',
  RolesView = 'roles_view',
  RolesEdit = 'roles_edit',
  OnboardingReview = 'onboarding_review',
}

export enum UserProductName {
  GigSafe = 'gigsafe',
  Payments = 'payments',
}
