import { memo, MouseEventHandler, ReactNode } from 'react'
import { CaretDownMinor, CaretUpMinor } from '@shopify/polaris-icons'
import { Button, contentColors, ButtonColor } from './Button'

interface Props {
  text?: string
  color?: ButtonColor
  icon?: ReactNode
  className?: any
  selected?: boolean
  loading?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const ActionButtonComponent = ({
  text,
  icon,
  color = 'primary',
  className,
  selected,
  loading,
  disabled,
  onClick,
}: Props) => (
  <Button
    variant="contained"
    size="small"
    color={color}
    loading={loading}
    className={className}
    selected={selected}
    disabled={disabled}
    onClick={onClick}
    leftIcon={icon}
    rightIcon={
      selected ? (
        <CaretUpMinor width={20} fill={contentColors[color]} />
      ) : (
        <CaretDownMinor width={20} fill={contentColors[color]} />
      )
    }
  >
    {text}
  </Button>
)

export const ActionButton = memo(ActionButtonComponent)
