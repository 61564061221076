import React from 'react'
import { Outlet } from 'react-router-dom'
import { SecureWrapper } from 'src/components/layout/SecureWrapper'

export const DashboardPage = () => {
  return (
    <SecureWrapper>
      <Outlet />
    </SecureWrapper>
  )
}
