import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { ActionButton } from 'src/components/shared/ActionButton'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { useSelector } from 'react-redux'
import { colors } from 'src/theme'
import { CirclePlusMinor, PlusMinor } from '@shopify/polaris-icons'
import { getTags } from 'src/selectors/tag'
import { Tag } from 'src/interfaces/tag'
import { Modal } from '@mui/material'
import { ModalHeader } from 'src/components/shared/ModalHeader'
import { CreateTagForm } from './create-tag-form/CreateTagForm'

interface Props {
  selectedTags?: Array<Tag>
  onSelectTag(tag: Tag): void
}

const AddTagButtonComponent = ({ selectedTags, onSelectTag }: Props) => {
  const tags = useSelector(getTags)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const container = useMemo(() => document.getElementById('root'), [])

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const filteredTags = useMemo(
    () =>
      tags.filter(
        (item) =>
          !selectedTags || !selectedTags.some((selectedTag) => selectedTag.name === item.name),
      ),
    [tags, selectedTags],
  )

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    return [
      ...filteredTags.map((item) => ({
        label: item.name,
        onClick: () => {
          onSelectTag(item)
          setAnchorEl(null)
        },
      })),
      {
        label: 'Create new tag',
        className: filteredTags.length
          ? tw`border-t border-0 border-solid border-[#EDEDED]`
          : undefined,
        color: colors.PRIMARY_PURPLE_500,
        icon: <CirclePlusMinor width={20} fill={colors.PRIMARY_PURPLE_500} />,
        onClick: () => {
          handleOpenModal()
          setAnchorEl(null)
        },
      },
    ]
  }, [filteredTags])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleCreateNewTag = useCallback(
    (tag: Tag) => {
      onSelectTag(tag)
      handleCloseModal()
    },
    [onSelectTag],
  )

  return (
    <>
      <ActionButton
        className={tw`px-2 h-[28px] rounded`}
        selected={!!anchorEl}
        color="light"
        text="Add tag"
        icon={<PlusMinor width={20} fill={colors.PRIMARY_PURPLE_500} />}
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        itemClassName={tw`min-w-[180px]`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      />
      <Modal
        container={container}
        open={Boolean(isModalOpen)}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        css={tw`flex justify-center items-center backdrop-blur`}
      >
        <div css={tw`bg-white rounded-lg`}>
          <ModalHeader title="Create new tag" onClose={handleCloseModal} />
          <CreateTagForm onSubmit={handleCreateNewTag} onClose={handleCloseModal} />
        </div>
      </Modal>
    </>
  )
}

export const AddTagButton = memo(AddTagButtonComponent)
