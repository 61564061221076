import { put, takeEvery, call } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  FetchTags,
  CreateTag,
  DeleteTag,
  DriverAddTag,
  DriverDeleteTag,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from './api/callApi'
import { Tag } from 'src/interfaces/tag'

function* onFetchTags(action: AsyncAction<void, Array<Tag>>) {
  try {
    const { payload }: { payload: Array<Tag> } = yield call(callSecureApi, Endpoints.FetchTags, {
      apiService: ApiService.Paraworks,
    })

    yield put(FetchTags.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch tags'

    yield put(FetchTags.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface CreateTagPayload {
  name: string
  description?: string
}

function* onCreateTag(action: AsyncAction<CreateTagPayload, Tag>) {
  const { name, description } = action.payload

  try {
    const { payload }: { payload: Tag } = yield call(callSecureApi, Endpoints.CreateTag, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        name,
        description,
      },
    })

    yield put(CreateTag.success(payload))
    yield put(FetchTags.request())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to create tag'

    yield put(CreateTag.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface DeleteTagPayload {
  tagId: string
}

function* onDeleteTag(action: AsyncAction<DeleteTagPayload, void>) {
  const { tagId } = action.payload

  try {
    yield call(callSecureApi, Endpoints.DeleteTag, {
      apiService: ApiService.Paraworks,
      method: 'DELETE',
      params: [
        {
          field: ':tagId',
          value: tagId,
        },
      ],
    })

    yield put(DeleteTag.success())
    yield put(FetchTags.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to delete tag'

    yield put(DeleteTag.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface DriverAddTagPayload {
  partnerDriverId: string
  tagId: string
}

function* onDriverAddTag(action: AsyncAction<DriverAddTagPayload, Tag>) {
  const { tagId, partnerDriverId } = action.payload

  try {
    const { payload }: { payload: Tag } = yield call(callSecureApi, Endpoints.DriverAddTag, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        partnerDriverId,
      },
      params: [
        {
          field: ':tagId',
          value: tagId,
        },
      ],
    })

    yield put(DriverAddTag.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to add tag to driver'

    yield put(DriverAddTag.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface DriverDeleteTagPayload {
  partnerDriverId: string
  tagId: string
}

function* onDriverDeleteTag(action: AsyncAction<DriverDeleteTagPayload, void>) {
  const { tagId, partnerDriverId } = action.payload

  try {
    yield call(callSecureApi, Endpoints.DriverDeleteTag, {
      apiService: ApiService.Paraworks,
      method: 'DELETE',
      body: {
        partnerDriverId,
      },
      params: [
        {
          field: ':tagId',
          value: tagId,
        },
      ],
    })

    yield put(DriverDeleteTag.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to delete tag from driver'

    yield put(DriverDeleteTag.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* tagSaga() {
  yield takeEvery(FetchTags.type.REQUEST, onFetchTags)
  yield takeEvery(CreateTag.type.REQUEST, onCreateTag)
  yield takeEvery(DeleteTag.type.REQUEST, onDeleteTag)
  yield takeEvery(DriverAddTag.type.REQUEST, onDriverAddTag)
  yield takeEvery(DriverDeleteTag.type.REQUEST, onDriverDeleteTag)
}
