import { createContext } from 'react'
import { ContractorStatus } from 'src/constants/contractor/enums'

export interface IContractorsContext {
  searchQuery: string
  setSearchQuery(value: string): void

  statusFilter: Array<ContractorStatus>
  setStatusFilter(statusFilter: Array<ContractorStatus>): void

  tagsFilter: Array<string>
  setTagsFilter(statusFilter: Array<string>): void

  refreshContractors(): Promise<void>

  openManageTags(): void
  closeManageTags(): void
}

export const ContractorsContext = createContext<IContractorsContext>({
  searchQuery: '',
  setSearchQuery: () => {},

  statusFilter: [],
  setStatusFilter: () => {},

  tagsFilter: [],
  setTagsFilter: () => {},

  refreshContractors: async () => {},

  openManageTags: () => {},
  closeManageTags: () => {},
})
