import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { createContext, MouseEvent } from 'react'
import { Onboarding } from 'src/interfaces/onboarding'

export interface IOnboardingsContext {
  selectedRows: GridRowSelectionModel
  setSelectedRows(rowSelectionModel: GridRowSelectionModel): void

  selectedOnboardings: Array<Onboarding>

  searchQuery: string
  setSearchQuery(value: string): void

  tagsFilter: Array<string>
  setTagsFilter(statusFilter: Array<string>): void

  refreshOnboardings(): void

  // edit action
  actionAnchor: null | HTMLElement
  onboardingToAction: Onboarding | null
  openOnboardingsItemAction(event: MouseEvent<HTMLButtonElement>, onboarding: Onboarding): void
  closeOnboardingsItemAction(): void

  isManageTagsOpen: boolean
  openManageTags(): void
  closeManageTags(): void
}

export const OnboardingsContext = createContext<IOnboardingsContext>({
  selectedRows: [],
  setSelectedRows: () => {},

  selectedOnboardings: [],

  searchQuery: '',
  setSearchQuery: () => {},

  tagsFilter: [],
  setTagsFilter: () => {},

  refreshOnboardings: () => {},

  actionAnchor: null,
  onboardingToAction: null,
  openOnboardingsItemAction: () => {},
  closeOnboardingsItemAction: () => {},

  isManageTagsOpen: false,
  openManageTags: () => {},
  closeManageTags: () => {},
})
