export enum PaymentStatus {
  Paid = 'paid',
  NotPaid = 'not_paid',
  Pending = 'pending',
  Failed = 'failed',
}

export enum StitchStatus {
  Created = 'created',
}

export enum StitchVendorStates {
  Approved = 'approved',
  Pending = 'pending',
  Document = 'document',
  Suspended = 'suspended',
  Rejected = 'rejected',
  ManualReview = 'manual_review',
}

export enum SinglePaymentInvalidReason {
  HasUnprocessed = 'has_unprocessed_payments',
  HasAlreadyProcessed = 'has_already_processed_payment',
}
