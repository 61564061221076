import { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { Modal, Typography } from '@mui/material'
import { colors } from 'src/theme'
import { ModalHeader } from './ModalHeader'
import { EditFieldForm } from './edit-field-form/EditFieldForm'
import { Button } from './Button'
import { EditMinor } from '@shopify/polaris-icons'

interface Props {
  title: string
  text?: string | null | number
  component?: ReactNode
  className?: any
  editPlaceholder?: string
  direction?: 'column' | 'row'
  onEdit?(value: string): void | Promise<void>
}

const DataRowComponent = ({
  text,
  title,
  className,
  component,
  editPlaceholder,
  direction = 'row',
  onEdit,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async (value: string) => {
      if (onEdit) {
        await onEdit(value)
      }

      handleCloseModal()
    },
    [onEdit],
  )

  return (
    <div
      css={[
        tw`px-4 py-3 border-t border-0 border-solid border-[#EDEDED] flex items-center justify-between`,
        className,
      ]}
    >
      <div
        css={[
          tw`flex w-full flex-col gap-1.5`,
          direction === 'row' && !onEdit && tw`flex-row justify-between gap-4`,
          onEdit && tw`max-w-[204px]`,
        ]}
      >
        <Typography color={colors.SHADES_GREY_400} variant="h6">
          {title}
        </Typography>
        {component || (
          <Typography
            style={{ wordWrap: 'break-word' }}
            css={direction === 'row' && !onEdit && tw`text-end`}
            variant="body2"
          >
            {text}
          </Typography>
        )}
      </div>
      {onEdit && (
        <Button
          color="grey"
          size="small"
          leftIcon={<EditMinor width={20} />}
          className={tw`ml-10 min-w-[36px]`}
          onClick={handleOpenModal}
        />
      )}
      {onEdit && (
        <Modal
          container={container}
          open={Boolean(isModalOpen)}
          onClose={handleCloseModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          css={tw`flex justify-center items-center backdrop-blur`}
        >
          <div css={tw`bg-white rounded-lg`}>
            <ModalHeader title={`Edit ${title}`} onClose={handleCloseModal} />
            <EditFieldForm
              label={title}
              initialValue={text}
              placeholder={editPlaceholder}
              onSubmit={handleSubmit}
              onClose={handleCloseModal}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export const DataRow = memo(DataRowComponent)
