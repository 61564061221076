import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { PublicWrapper } from 'src/components/layout/PublicWrapper'
import { Button } from 'src/components/shared/Button'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import SentImage from 'src/assets/images/link-sent.svg'
import { ROUTES } from 'src/constants/routes'

export const ForgotPasswordLinkSentPage = () => {
  const navigate = useNavigate()

  const handleBack = useCallback(() => {
    navigate(ROUTES.SIGN_IN_URL)
  }, [])

  return (
    <PublicWrapper>
      <div css={tw`bg-white rounded-3xl p-10 flex flex-col pt-8`}>
        <img src={SentImage} css={tw`self-center`} />
        <Typography variant="h2" css={tw`mt-6 text-center w-[330px]`}>
          Reset instructions have been sent
        </Typography>
        <Typography color="#847E96" css={tw`mt-2 w-[330px] text-center`}>
          Please check your inbox and follow further instructions to reset your password.
        </Typography>
        <Button color="light" className={tw`mt-8`} onClick={handleBack}>
          Back to login
        </Button>
      </div>
    </PublicWrapper>
  )
}
