import React, { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { CancelSmallMinor } from '@shopify/polaris-icons'
import { Tag } from 'src/interfaces/tag'
import { Typography } from '@mui/material'
import { cutLongWord } from 'src/utils/baseUtils'

interface Props {
  tag: Tag
  onRemove(tag: Tag): void
}

const RemovableTagComponent = ({ tag, onRemove }: Props) => {
  const handleRemove = useCallback(() => {
    onRemove(tag)
  }, [tag, onRemove])

  return (
    <div css={tw`bg-[#EDEDED] rounded flex`}>
      <Typography variant="subtitle1" css={tw`my-1 mr-0 ml-2`}>
        {cutLongWord(tag.name)}
      </Typography>
      <div css={tw`m-1 mr-2 h-5 cursor-pointer hover:opacity-50`} onClick={handleRemove}>
        <CancelSmallMinor width={20} />
      </div>
    </div>
  )
}

export const RemovableTag = memo(RemovableTagComponent)
