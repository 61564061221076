import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { ActionButton } from '../shared/ActionButton'
import { ActionMenu } from '../shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { useDispatch, useSelector } from 'react-redux'
import { getMarkets, getSelectedMarket } from 'src/selectors/market'
import { SetSelectedMarket } from 'src/constants/actionTypes'
import { colors } from 'src/theme'
import { CircleTickMinor } from '@shopify/polaris-icons'

const MarketSelectButtonComponent = () => {
  const dispatch = useDispatch()

  const markets = useSelector(getMarkets)
  const selectedMarket = useSelector(getSelectedMarket)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    return [
      {
        label: 'All markets',
        color: !selectedMarket ? colors.COLORS_GREEN_500 : colors.GRAY_BLACK,
        rightIcon: !selectedMarket ? (
          <CircleTickMinor css={tw`ml-auto self-end`} width={20} fill={colors.COLORS_GREEN_500} />
        ) : undefined,
        onClick: () => {
          dispatch(SetSelectedMarket.success(null))
          setAnchorEl(null)
        },
      },
      ...markets.map((item) => ({
        label: item.title,
        color: item.id === selectedMarket?.id ? colors.COLORS_GREEN_500 : colors.GRAY_BLACK,
        rightIcon:
          item.id === selectedMarket?.id ? (
            <CircleTickMinor css={tw`ml-auto self-end`} width={20} fill={colors.COLORS_GREEN_500} />
          ) : undefined,
        onClick: () => {
          dispatch(SetSelectedMarket.success(item))
          setAnchorEl(null)
        },
      })),
    ]
  }, [markets, selectedMarket])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <ActionButton
        className={tw`mr-4 px-4`}
        selected={!!anchorEl}
        color="dark"
        text={selectedMarket?.title || 'All markets'}
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        itemClassName={tw`min-w-[180px]`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      />
    </>
  )
}

export const MarketSelectButton = memo(MarketSelectButtonComponent)
