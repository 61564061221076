import React, { memo, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { Modal, Typography } from '@mui/material'
import { colors } from 'src/theme'
import { ChatMajor, DuplicateMinor, EmailMajor } from '@shopify/polaris-icons'
import { showToast } from 'src/utils/toast'
import { Button } from './Button'

const EMAIL = 'help@gigsafe.com'
const PHONE = '+1 (820) 444-7233'

const GetHelpComponent = () => {
  const [isOpen, setIsOpen] = useState(false)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleCopyEmail = useCallback(() => {
    navigator.clipboard.writeText(EMAIL)

    showToast('Copied!')
  }, [])

  const handleCopyPhone = useCallback(() => {
    navigator.clipboard.writeText(PHONE)

    showToast('Copied!')
  }, [])

  return (
    <>
      <Button
        round
        color="black"
        size="medium"
        rightIcon={<ChatMajor width={20} fill="white" />}
        onClick={handleOpen}
        className={tw`px-4`}
      >
        Get help
      </Button>
      <Modal
        container={container}
        open={Boolean(isOpen)}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        css={tw`flex flex-col justify-center items-center backdrop-blur`}
      >
        <div css={tw`flex-col p-4 pt-6 bg-white rounded-2xl`}>
          <Typography variant="h2">Get help now!</Typography>
          <Typography css={tw`mt-2 mb-6`} color={colors.SHADES_GREY_400}>
            Choose your preferred option to reach us.
          </Typography>
          <div css={tw`bg-[#EDEDED] h-[1px] w-full`} />
          <div css={tw`flex flex-row justify-between items-center px-2 py-4`}>
            <div css={tw`flex flex-row items-center`}>
              <div
                css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mr-4`}
              >
                <EmailMajor width={20} />
              </div>
              <div>
                <Typography css={tw`mb-0.5`} variant="h4">
                  Via email
                </Typography>
                <Typography color={colors.SHADES_GREY_400}>{EMAIL}</Typography>
              </div>
            </div>
            <Button
              leftIcon={<DuplicateMinor width={20} fill={colors.PRIMARY_PURPLE_500} />}
              color="light"
              size="small"
              onClick={handleCopyEmail}
            >
              Copy
            </Button>
          </div>
          <div css={tw`bg-[#EDEDED] h-[1px] w-full`} />
          <div css={tw`flex flex-row justify-between items-center px-2 py-4`}>
            <div css={tw`flex flex-row items-center`}>
              <div
                css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mr-4`}
              >
                <ChatMajor width={20} />
              </div>
              <div>
                <Typography css={tw`mb-0.5 max-w-[140px]`} variant="h4">
                  Via phone number (text only)
                </Typography>
                <Typography color={colors.SHADES_GREY_400}>{PHONE}</Typography>
              </div>
            </div>
            <Button
              leftIcon={<DuplicateMinor width={20} fill={colors.PRIMARY_PURPLE_500} />}
              color="light"
              size="small"
              onClick={handleCopyPhone}
            >
              Copy
            </Button>
          </div>
          <div css={tw`bg-[#EDEDED] h-[1px] w-full`} />
          <Button
            onClick={handleClose}
            variant="contained"
            color="black"
            className={tw`mt-6 w-[356px]`}
          >
            Back
          </Button>
        </div>
      </Modal>
    </>
  )
}

export const GetHelp = memo(GetHelpComponent)
