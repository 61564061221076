import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'

interface Props {
  text?: string
  className?: any
  textColor?: string
}

const ChipComponent = ({ text, className, textColor = 'black' }: Props) => (
  <div css={[tw`px-2 py-0.5 rounded-[10px] bg-[#EDEDED]`, className]}>
    <Typography color={textColor} variant="subtitle2">
      {text}
    </Typography>
  </div>
)

export const Chip = memo(ChipComponent)
