import React, { memo } from 'react'
import tw from 'twin.macro'
import { TableTag } from './TableTag'
import { Tooltip, Typography } from '@mui/material'
import { colors } from 'src/theme'
import { Tag } from 'src/interfaces/tag'

interface Props {
  tags: Array<Tag>
}

const TableTagsComponent = ({ tags }: Props) => {
  if (!tags?.length) {
    return null
  }

  if (tags.length > 2) {
    return (
      <div css={tw`flex flex-wrap gap-2`}>
        <TableTag tag={tags[0].name} />
        <Tooltip title={tags.slice(1, tags.length).join(', ')} placement="bottom-start">
          <span>
            <div css={tw`rounded py-0.5 px-2 bg-[#D8E5FF]`}>
              <Typography variant="subtitle2" color={colors.COLORS_BLUE_500}>{`+${
                tags.length - 1
              } tags`}</Typography>
            </div>
          </span>
        </Tooltip>
      </div>
    )
  }

  return (
    <div css={tw`flex flex-wrap gap-2`}>
      {tags.slice(0, 2).map((item) => (
        <TableTag key={item.id} tag={item.name} />
      ))}
    </div>
  )
}

export const TableTags = memo(TableTagsComponent)
