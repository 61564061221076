import React, { memo, useCallback, useMemo } from 'react'
import { Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { Button } from './Button'
import { ModalHeader } from './ModalHeader'
import { ChatMajor, DuplicateMinor, EmailMajor, SendMajor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { showToast } from 'src/utils/toast'
import { show as showIntercom } from '@intercom/messenger-js-sdk'

interface Props {
  isOpen?: boolean
  onClose(): void
}

const EMAIL = 'support@gigsafe.com'

const SupportModalComponent = ({ isOpen, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  const handleCopyEmail = useCallback(() => {
    navigator.clipboard.writeText(EMAIL)

    showToast('Copied!')
  }, [])

  const handleOpenIntercom = useCallback(() => {
    showIntercom()
  }, [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur`}
    >
      <div css={tw`bg-white rounded-lg w-[346px] overflow-hidden max-h-full flex flex-col`}>
        <ModalHeader title="Support" onClose={onClose} />
        <div css={tw`p-4 bg-[#F6F6F6] flex  flex-col overflow-scroll`}>
          <div css={tw`rounded-lg bg-white overflow-hidden mb-4`}>
            <div css={tw`flex flex-row items-center px-4 py-3`}>
              <EmailMajor width={20} height={20} />
              <div css={tw`ml-3`}>
                <Typography variant="h5">Via email</Typography>
                <Typography variant="h6" color={colors.SHADES_GREY_400}>
                  {EMAIL}
                </Typography>
              </div>
            </div>
            <div css={tw`px-4 py-3 border-0 border-t border-b border-solid border-[#EDEDED]`}>
              <Typography variant="body2" color={colors.SHADES_GREY_400}>
                Email us your issue, and we'll typically respond within 24 hours. You'll receive our
                reply directly in your inbox.
              </Typography>
            </div>
            <div css={tw`px-4 py-3`}>
              <Button
                onClick={handleCopyEmail}
                size="small"
                color="light"
                rightIcon={<DuplicateMinor width={20} fill={colors.PRIMARY_PURPLE_500} />}
              >
                Copy email address
              </Button>
            </div>
          </div>
          <div css={tw`rounded-lg bg-white overflow-hidden`}>
            <div css={tw`flex flex-row items-center px-4 py-3`}>
              <ChatMajor width={20} height={20} />
              <div css={tw`ml-3`}>
                <Typography variant="h5">Via chat</Typography>
              </div>
            </div>
            <div css={tw`px-4 py-3 border-0 border-t border-b border-solid border-[#EDEDED]`}>
              <Typography variant="body2" color={colors.SHADES_GREY_400}>
                Send us your issue through chat, and we'll typically respond within 24 hours. You'll
                receive our reply directly in your email inbox.
              </Typography>
            </div>
            <div css={tw`px-4 py-3`}>
              <Button
                onClick={handleOpenIntercom}
                size="small"
                color="light"
                rightIcon={<SendMajor width={20} fill={colors.PRIMARY_PURPLE_500} />}
              >
                Start chat
              </Button>
            </div>
          </div>
        </div>
        <div
          css={tw`flex flex-row justify-between items-end border-0 border-t border-solid border-[#EDEDED] p-4 pt-3`}
        >
          <Button size="small" color="grey" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const SupportModal = memo(SupportModalComponent)
