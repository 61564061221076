import React, { memo, useCallback, useMemo, useState } from 'react'
import { Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import { Button, ButtonColor } from './Button'
import { showToast } from 'src/utils/toast'
import { ModalHeader } from './ModalHeader'

interface Props {
  isOpen?: boolean
  title?: string
  text?: string
  submitLabel: string
  color?: ButtonColor
  onSubmit(): void | Promise<void>
  onClose(): void
}

const SensitiveActionModalComponent = ({
  isOpen,
  title,
  text,
  submitLabel,
  color = 'red',
  onSubmit,
  onClose,
}: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)

    try {
      await onSubmit()
    } catch (err) {
      showToast(err as any, { variant: 'error' })
    }

    setIsLoading(false)
  }, [onSubmit])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur p-4`}
    >
      <div css={tw`min-w-[340px] flex-col bg-white rounded-lg`}>
        <ModalHeader title={title || 'Please confirm'} onClose={onClose} />
        {text && (
          <Typography css={tw`m-4`} variant="h6" color={colors.SHADES_GREY_400}>
            {text}
          </Typography>
        )}
        <div
          css={tw`flex justify-between items-center px-4 py-3 border-0 border-t border-solid  border-[#EDEDED]`}
        >
          <Button
            className={tw`px-8`}
            loading={isLoading}
            onClick={handleSubmit}
            color={color}
            size="small"
          >
            {submitLabel}
          </Button>
          <Button
            className={tw`px-8`}
            disabled={isLoading}
            onClick={onClose}
            color="grey"
            size="small"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const SensitiveActionModal = memo(SensitiveActionModalComponent)
