import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { PublicWrapper } from 'src/components/layout/PublicWrapper'
import { Button } from 'src/components/shared/Button'
import { ChevronLeftMinor } from '@shopify/polaris-icons'
import { ForgotPasswordForm } from 'src/components/auth/forgot-password-form/ForgotPasswordForm'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { ROUTES } from 'src/constants/routes'

export const ForgotPasswordPage = () => {
  const navigate = useNavigate()

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [])

  const handleSuccess = useCallback(() => {
    navigate(ROUTES.FORGOT_PASSWORD_LINK_SENT_URL)
  }, [])

  return (
    <PublicWrapper>
      <div css={tw`bg-white rounded-3xl p-4 flex pb-12`}>
        <div css={tw`w-[326px] mx-6`}>
          <Button
            color="grey"
            size="medium"
            className={tw`mt-4`}
            leftIcon={<ChevronLeftMinor width={20} />}
            onClick={handleBack}
          >
            Back
          </Button>
          <Typography variant="h2" css={tw`mb-2 mt-8`}>
            Forgot your password?
          </Typography>
          <Typography color="#847E96" css={tw`mt-2 mb-4 w-[280px]`}>
            We'll email you a link to reset your password and access your account.
          </Typography>
          <ForgotPasswordForm onSuccess={handleSuccess} />
        </div>
      </div>
    </PublicWrapper>
  )
}
