import React, { memo, useCallback, useContext, MouseEvent } from 'react'
import tw from 'twin.macro'
import { OnboardingsContext } from '../Onboardings.context'
import { Onboarding } from 'src/interfaces/onboarding'
import { Button } from 'src/components/shared/Button'
import { HorizontalDotsMinor } from '@shopify/polaris-icons'

interface Props {
  onboarding: Onboarding
}

const OnboardingsItemActionButtonComponent = ({ onboarding }: Props) => {
  const { openOnboardingsItemAction } = useContext(OnboardingsContext)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      openOnboardingsItemAction(event, onboarding)
    },
    [onboarding],
  )

  return (
    <Button
      className={tw`px-2 rounded h-[28px]`}
      color="grey"
      size="small"
      leftIcon={<HorizontalDotsMinor width={20} height={20} />}
      onClick={handleClick}
    />
  )
}

export const OnboardingsItemActionButton = memo(OnboardingsItemActionButtonComponent)
