import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UploadPartnerDriversCSV } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { InviteWorkersViaCSVFormValues } from './InviteWorkersViaCSVForm.interface'
import { InviteWorkersViaCSVSchema } from './InviteWorkersViaCSVForm.validator'
import tw from 'twin.macro'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import { getMarkets } from 'src/selectors/market'
import { showToast } from 'src/utils/toast'
import { getPartnerId } from 'src/selectors/user'
import { getOnboardingTemplates } from 'src/selectors/onboarding'
import { UploadedFile } from 'src/components/shared/UploadedFile'
import { FileUploadInput } from 'src/components/shared/FileUploadInput'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { Button } from 'src/components/shared/Button'
import { handleApiError } from 'src/utils/errorHandlers'

interface Props {
  onSubmit(): void
  onClose(): void
}

const ACCESS_TYPES = ['text/csv']

export const InviteWorkersViaCSVForm = ({ onSubmit, onClose }: Props) => {
  const dispatch = useDispatch()

  const markets = useSelector(getMarkets)
  const onboardingTemplates = useSelector(getOnboardingTemplates)
  const partnerId = useSelector(getPartnerId)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)

  const handleFormSubmit = async (values: InviteWorkersViaCSVFormValues) => {
    if (!partnerId) {
      return
    }

    setIsLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        UploadPartnerDriversCSV.request({ file, partnerId, ...values }),
      )

      showToast('File uploaded!')

      onSubmit()
      onClose()
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUploadFile = useCallback(async (file: File) => {
    setFile(file)
  }, [])

  const handleRemoveFile = useCallback(() => {
    setFile(null)
  }, [])

  return (
    <Formik
      initialValues={{
        partnerMarketId: markets?.length === 1 ? markets[0].id : undefined,
        collectDataTemplateId:
          onboardingTemplates?.length === 1 ? onboardingTemplates[0].id : undefined,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={InviteWorkersViaCSVSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <div css={tw`px-4 pt-4`}>
            <div css={tw`w-[304px] mb-4`}>
              <FormikSelect
                css={tw`w-full`}
                name="collectDataTemplateId"
                label="Select onboarding"
                options={onboardingTemplates.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </div>
            <div css={tw`w-[304px] mb-4`}>
              <FormikSelect
                css={tw`w-full`}
                name="partnerMarketId"
                label="Select market"
                options={markets.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </div>
            <div>
              <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                Upload file
              </Typography>
              <div css={tw`bg-white rounded-xl mt-2`}>
                {file ? (
                  <UploadedFile file={file} onRemove={handleRemoveFile} />
                ) : (
                  <FileUploadInput
                    // 10 MB
                    fileSizeLimit={10000000}
                    acceptTypes={ACCESS_TYPES}
                    onSelect={handleUploadFile}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            css={tw`flex flex-row justify-between items-end  border-0 border-t border-solid border-[#EDEDED] mt-4 p-4 pt-3`}
          >
            <Button
              variant="contained"
              loading={isLoading}
              size="small"
              color="grey"
              onClick={onClose}
            >
              Cancel
            </Button>
            <div css={tw`flex flex-col items-end`}>
              <Button
                variant="contained"
                size="small"
                loading={isLoading}
                disabled={!file}
                onClick={() => handleSubmit()}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}
