import React, { memo, useCallback, useContext, useMemo } from 'react'
import { OnboardingsContext } from '../Onboardings.context'
import { MenuActionItem } from 'src/interfaces/ui'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { ActivatePartnerDriver, DeactivatePartnerDriver } from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'

const OnboardingsItemActionMenuComponent = () => {
  const dispatch = useDispatch()

  const { actionAnchor, onboardingToAction, refreshOnboardings, closeOnboardingsItemAction } =
    useContext(OnboardingsContext)

  const isOpen = Boolean(actionAnchor)

  const handleActivatePartnerDriver = useCallback(async () => {
    if (!onboardingToAction) {
      return
    }

    try {
      await createAsyncAction(
        dispatch,
        ActivatePartnerDriver.request({
          partnerDriverId: onboardingToAction.id,
        }),
      )
      showToast('Contractor is activated.')
      refreshOnboardings()
    } catch (err: any) {
      handleApiError(err)
    } finally {
      closeOnboardingsItemAction()
    }
  }, [onboardingToAction])

  const handleDeactivatePartnerDriver = useCallback(async () => {
    if (!onboardingToAction) {
      return
    }

    try {
      await createAsyncAction(
        dispatch,
        DeactivatePartnerDriver.request({
          partnerDriverId: onboardingToAction.id,
        }),
      )
      showToast('Contractor is deactivated.')
      refreshOnboardings()
    } catch (err: any) {
      handleApiError(err)
    } finally {
      closeOnboardingsItemAction()
    }
  }, [onboardingToAction])

  const actionItems: MenuActionItem[] = useMemo(() => {
    const res = []

    if (onboardingToAction?.isActive) {
      res.push({
        label: 'Deactivate',
        onClick: handleDeactivatePartnerDriver,
      })
    } else {
      res.push({
        label: 'Activate',
        onClick: handleActivatePartnerDriver,
      })
    }

    return res
  }, [onboardingToAction, handleActivatePartnerDriver, handleDeactivatePartnerDriver])

  return (
    <ActionMenu
      anchorEl={actionAnchor}
      isOpen={isOpen}
      items={actionItems}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={closeOnboardingsItemAction}
    />
  )
}

export const OnboardingsItemActionMenu = memo(OnboardingsItemActionMenuComponent)
