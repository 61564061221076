import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ResetPassword } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { ResetPasswordFormValues } from './ResetPasswordForm.interface'
import { ResetPasswordSchema } from './ResetPasswordForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'

const initialValues: ResetPasswordFormValues = {
  password: '',
  confirmPassword: '',
}

interface Props {
  token: string
  onSuccess(): void
}

export const ResetPasswordForm = ({ token, onSuccess }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFormSubmit = async (values: ResetPasswordFormValues) => {
    setIsLoading(true)

    try {
      await createAsyncAction(dispatch, ResetPassword.request({ token, ...values }))
      showToast('Password is changed.')
      onSuccess()
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={ResetPasswordSchema}
    >
      {({ handleSubmit }) => (
        <div css={tw`w-full`}>
          <FormikTextField
            name="password"
            type="password"
            label="Create password"
            css={tw`w-full mb-6`}
            placeholder="Enter new password"
          />
          <FormikTextField
            name="confirmPassword"
            type="password"
            label="Repeat password"
            css={tw`w-full mb-6`}
            placeholder="Repeat new password"
          />
          <Button
            className={tw`w-full`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Create password
          </Button>
        </div>
      )}
    </Formik>
  )
}
