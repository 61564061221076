import { Typography } from '@mui/material'
import { memo, useContext } from 'react'
import tw from 'twin.macro'
import EmptyImage from 'src/assets/images/empty-payment.svg'
import EmptySearchImage from 'src/assets/images/empty-search.svg'
import { colors } from 'src/theme'
import { PaymentsContext } from './Payments.context'

const EmptyPaymentsComponent = () => {
  const { searchQuery, statusFilter } = useContext(PaymentsContext)

  const isSearchUsed = Boolean(searchQuery || statusFilter.length)

  return (
    <div css={tw`flex flex-1 h-full justify-center items-center flex-col`}>
      <img src={isSearchUsed ? EmptySearchImage : EmptyImage} />
      <Typography css={tw`mb-1 mt-2`} variant="h4" fontWeight={600}>
        {isSearchUsed ? 'No payments found' : 'This board is empty'}
      </Typography>
      <Typography color={colors.SHADES_GREY_400} variant="body2">
        {isSearchUsed
          ? 'Please try a different search query or filters.'
          : 'No payment records found. Please add new payments to see them listed here.'}
      </Typography>
    </div>
  )
}

export const EmptyPayments = memo(EmptyPaymentsComponent)
