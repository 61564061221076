import React from 'react'
import { UserPermission, UserProductName } from 'src/constants/user'
import { useSelector } from 'react-redux'
import { getAccessInfo } from 'src/selectors/user'
import { Navigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'

export const NavigateToInitialRoute = () => {
  const accessInfo = useSelector(getAccessInfo)

  if (!accessInfo) {
    return null
  }

  if (accessInfo?.permission?.includes(UserPermission.OnboardingView)) {
    return <Navigate to={ROUTES.ONBOARDING_URL} />
  }

  if (
    accessInfo?.permission?.includes(UserPermission.PaymentsView) &&
    accessInfo?.products?.some((item) => item.product === UserProductName.Payments)
  ) {
    return <Navigate to={ROUTES.PAYMENTS_URL} />
  }

  return null
}
