import { put, takeEvery, call } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  CreatePartnerDriver,
  UploadPartnerDriversCSV,
  UpdatePartnerDriver,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from '../api/callApi'

interface CreatePartnerDriverRequest {
  firstName: string
  lastName: string
  phone: string
  email: string
  partnerMarketId: string
  collectDataTemplateId: string
  tagNames?: string[]
}

function* onCreatePartnerDriver(action: AsyncAction<CreatePartnerDriverRequest, { id: string }>) {
  const { firstName, lastName, phone, email, partnerMarketId, collectDataTemplateId, tagNames } =
    action.payload

  try {
    const { payload }: { payload: { id: string } } = yield call(
      callSecureApi,
      Endpoints.CreatePartnerDriver,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        body: {
          firstName,
          lastName,
          phone,
          email,
          partnerMarketId,
          collectDataTemplateId,
          tagNames,
        },
      },
    )

    yield put(CreatePartnerDriver.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to create partner driver'

    yield put(CreatePartnerDriver.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UpdatePartnerDriverRequest {
  externalId: string
  partnerDriverId: string
}

function* onUpdatePartnerDriver(action: AsyncAction<UpdatePartnerDriverRequest, { id: string }>) {
  const { externalId, partnerDriverId } = action.payload

  try {
    const { payload }: { payload: { id: string } } = yield call(
      callSecureApi,
      Endpoints.UpdatePartnerDriver,
      {
        method: 'PUT',
        apiService: ApiService.Paraworks,
        body: {
          externalId,
        },
        params: [
          {
            field: ':partnerDriverId',
            value: partnerDriverId,
          },
        ],
      },
    )

    yield put(UpdatePartnerDriver.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to create partner driver'

    yield put(UpdatePartnerDriver.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadPartnerDriversCSVRequest {
  file: File
  partnerMarketId: string
  collectDataTemplateId: string
}

function* onUploadPartnerDriversCSV(action: AsyncAction<UploadPartnerDriversCSVRequest>) {
  const { file, partnerMarketId, collectDataTemplateId } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('partnerMarketId', partnerMarketId)
    formData.append('onboardingTemplateId', collectDataTemplateId)

    yield call(callSecureApi, Endpoints.UploadPartnerDriversCSV, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(UploadPartnerDriversCSV.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload drivers csv'

    yield put(UploadPartnerDriversCSV.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingInviteWorkersSaga() {
  yield takeEvery(CreatePartnerDriver.type.REQUEST, onCreatePartnerDriver)
  yield takeEvery(UpdatePartnerDriver.type.REQUEST, onUpdatePartnerDriver)
  yield takeEvery(UploadPartnerDriversCSV.type.REQUEST, onUploadPartnerDriversCSV)
}
