import { put, takeEvery, call } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchUser } from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from './api/callApi'
import { AccessInfo, User } from 'src/interfaces/user'

function* onFetchUser(action: AsyncAction<undefined, { user: User; accessInfo: AccessInfo }>) {
  try {
    const { payload }: { payload: { user: User; accessInfo: AccessInfo } } = yield call(
      callSecureApi,
      Endpoints.FetchUser,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(FetchUser.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch user'

    yield put(FetchUser.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* userSaga() {
  yield takeEvery(FetchUser.type.REQUEST, onFetchUser)
}
