import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SignIn } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { SignInFormValues } from './SignInForm.interface'
import { SignInSchema } from './SignInForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { handleApiError } from 'src/utils/errorHandlers'

const initialValues: SignInFormValues = {
  email: '',
  password: '',
}

export const SignInForm = () => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFormSubmit = async (values: SignInFormValues) => {
    setIsLoading(true)

    try {
      await createAsyncAction(dispatch, SignIn.request(values))
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={SignInSchema}
    >
      {({ handleSubmit }) => (
        <div css={tw`w-full`}>
          <FormikTextField
            name="email"
            label="Email"
            css={tw`w-full mb-6`}
            placeholder="Enter your email"
          />
          <FormikTextField
            name="password"
            type="password"
            label="Password"
            css={tw`w-full mb-6`}
            placeholder="Enter your password"
          />
          <Button
            className={tw`w-full`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Log In
          </Button>
        </div>
      )}
    </Formik>
  )
}
