import React, { memo, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { useDispatch, useSelector } from 'react-redux'
import { ProductsMinor } from '@shopify/polaris-icons'
import { getTags } from 'src/selectors/tag'
import { Modal, Typography } from '@mui/material'
import { ModalHeader } from 'src/components/shared/ModalHeader'
import { CreateTagForm } from './create-tag-form/CreateTagForm'
import { Button } from 'src/components/shared/Button'
import { ManageableTag } from './ManageableTag'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { DeleteTag } from 'src/constants/actionTypes'
import { Tag } from 'src/interfaces/tag'
import { showToast } from 'src/utils/toast'
import { colors } from 'src/theme'
import { handleApiError } from 'src/utils/errorHandlers'

interface Props {
  isManageTagsOpen?: boolean
  openManageTags(): void
  closeManageTags(): void
}

const ManageTagsButtonComponent = ({
  isManageTagsOpen,
  openManageTags,
  closeManageTags,
}: Props) => {
  const dispatch = useDispatch()

  const tags = useSelector(getTags)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleOpenCreateModal = useCallback(() => {
    setIsCreateModalOpen(true)
  }, [])

  const handleCloseCreateModal = useCallback(() => {
    setIsCreateModalOpen(false)
  }, [])

  const handleDeleteTag = useCallback(async (removedTag: Tag) => {
    try {
      await createAsyncAction(
        dispatch,
        DeleteTag.request({
          tagId: removedTag.id,
        }),
      )

      showToast('Tag is removed.')
    } catch (err) {
      handleApiError(err)
    }
  }, [])

  return (
    <>
      <Button
        className={tw`mr-2`}
        color="white"
        size="small"
        leftIcon={<ProductsMinor width={20} />}
        onClick={openManageTags}
      >
        Manage tags
      </Button>
      <Modal
        container={container}
        open={Boolean(isManageTagsOpen)}
        onClose={closeManageTags}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        css={tw`flex justify-center items-center backdrop-blur  py-8`}
      >
        <div css={tw`bg-white rounded-lg w-[346px] overflow-hidden max-h-full flex flex-col`}>
          <ModalHeader title="Create new tag" onClose={closeManageTags} />
          <div css={tw`p-4 bg-[#F6F6F6] flex  flex-col overflow-scroll`}>
            {tags.length ? (
              <div css={tw`bg-white rounded-lg flex flex-col`}>
                {tags.map((item, index) => (
                  <ManageableTag
                    tag={item}
                    key={item.id}
                    onRemove={handleDeleteTag}
                    hideSeparator={index === tags.length - 1}
                  />
                ))}
              </div>
            ) : (
              <div>
                <Typography css={tw`text-center`} variant="h4">
                  No tags were created
                </Typography>
                <Typography
                  css={tw`text-center mt-0.5`}
                  variant="body2"
                  color={colors.SHADES_GREY_400}
                >
                  Create a new tag to manage your fleet efficiently
                </Typography>
              </div>
            )}
          </div>
          <div
            css={tw`flex flex-row justify-between items-end border-0 border-t border-solid border-[#EDEDED] p-4 pt-3`}
          >
            <Button size="small" color="grey" onClick={closeManageTags}>
              Close
            </Button>
            <div css={tw`flex flex-col items-end`}>
              <Button size="small" onClick={handleOpenCreateModal}>
                Create new tag
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        container={container}
        open={Boolean(isCreateModalOpen)}
        onClose={handleCloseCreateModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        css={tw`flex justify-center items-center backdrop-blur`}
      >
        <div css={tw`bg-white rounded-lg`}>
          <ModalHeader title="Create new tag" onClose={handleCloseCreateModal} />
          <CreateTagForm onSubmit={handleCloseCreateModal} onClose={handleCloseCreateModal} />
        </div>
      </Modal>
    </>
  )
}

export const ManageTagsButton = memo(ManageTagsButtonComponent)
