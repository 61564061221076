import { FormHelperText, TextField, Typography } from '@mui/material'
import { FormControl, Autocomplete, AutocompleteProps } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { colors } from 'src/theme'
import tw from 'twin.macro'

export interface BasicAutocompleteOption {
  label: string
  subLabel: string
  value: any
}

export interface BasicAutocompleteProps<T>
  extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
  name: keyof T
  css?: any
  label?: string
  placeholder?: string
  value?: any
  error?: boolean
  helperText?: string | false
  options: Array<BasicAutocompleteOption>
  startIcon?: any
  onChange?(value: any): void
  onBlur?: (e: React.FocusEvent<any>) => void
}

export const BasicAutocomplete = <T extends unknown>({
  css,
  label,
  placeholder,
  value,
  error,
  helperText,
  options,
  startIcon,
  onChange,
  onBlur,
  ...props
}: BasicAutocompleteProps<T>) => {
  const renderOption = useCallback((props: any, option: BasicAutocompleteOption) => {
    return (
      <div {...props} key={option.subLabel}>
        <div css={tw`flex flex-col w-full`}>
          <Typography variant="subtitle1" fontWeight={500}>
            {option.label}
          </Typography>
          {option.subLabel && (
            <Typography variant="body2" color={colors.SHADES_GREY_400}>
              {option.subLabel}
            </Typography>
          )}
        </div>
      </div>
    )
  }, [])

  const inputValue = useMemo(() => options.find((item) => item.value === value), [])

  return (
    <div css={css}>
      <Typography css={tw`mb-1`} variant="h6" color={colors.GRAY_COOL_GRAY}>
        {label}
      </Typography>
      <FormControl fullWidth error={error}>
        <Autocomplete<BasicAutocompleteOption>
          {...props}
          value={inputValue}
          inputValue={inputValue?.label}
          onChange={(event: any, newValue) => {
            if (onChange) {
              onChange(newValue?.value)
            }
          }}
          options={options}
          placeholder={placeholder}
          renderOption={renderOption}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                onBlur={onBlur}
                InputProps={{ ...params.InputProps, startAdornment: startIcon }}
                placeholder={placeholder}
              />
            )
          }}
        />
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </div>
  )
}
