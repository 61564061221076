import { Logout, FetchUser } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { UserState } from './types'

const getInitialState = (): UserState => ({
  user: null,
  accessInfo: null,
  isUserInitialized: false,
})

export const userReducer = (
  state: UserState = getInitialState(),
  action: Action<any>,
): UserState => {
  switch (action.type) {
    case FetchUser.type.SUCCESS:
      return {
        ...state,
        isUserInitialized: true,
        user: action.payload.user,
        accessInfo: action.payload.accessInfo,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
