import { DataGridPro, DataGridProProps, GridValidRowModel } from '@mui/x-data-grid-pro'
import React, { memo } from 'react'

interface Props<R extends GridValidRowModel = any> extends DataGridProProps<R> {}

const GridTableComponent = ({ rows, columns, ...rest }: Props) => {
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnReorder
      disableColumnSelector
      {...rest}
    />
  )
}

export const GridTable = memo(GridTableComponent)
