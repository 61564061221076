import { memo, useMemo } from 'react'
import { ContractorDetails } from 'src/interfaces/contractor'
import { ContractorStepItem } from './ContractorStepItem'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { ContractorOnboardingStatus } from 'src/constants/contractor/enums'

interface Props {
  contractorDetails: ContractorDetails
  filterByStatus?: CollectedDataStatus
  collapsible?: boolean
  onRefresh: () => void
}

const ONBOARDING_STATUSES_WITH_DELAYED_INFO = [
  ContractorOnboardingStatus.Created,
  ContractorOnboardingStatus.WaitingForApproval,
  ContractorOnboardingStatus.Failed,
  ContractorOnboardingStatus.PartiallyCompleted,
]

const ContractorStepsComponent = ({
  contractorDetails,
  filterByStatus,
  collapsible,
  onRefresh,
}: Props) => {
  const steps = useMemo(
    () =>
      contractorDetails.collectedData
        .filter((item) => !filterByStatus || item.status === filterByStatus)
        // sort delayed last
        .sort((x, y) => Number(x.collectDataStep.delayed) - Number(y.collectDataStep.delayed)),
    [contractorDetails.collectedData],
  )

  const firstDelayedStepIndex = useMemo(
    () => steps.findIndex((item) => !!item.collectDataStep.delayed),
    [steps],
  )

  return (
    <div>
      {steps.map((item, index) => (
        <ContractorStepItem
          collectedData={item}
          key={item.id}
          contractorDetails={contractorDetails}
          onRefreshContractors={onRefresh}
          collapsible={collapsible}
          delayed={
            ONBOARDING_STATUSES_WITH_DELAYED_INFO.includes(contractorDetails?.onboarding?.status) &&
            firstDelayedStepIndex === index
          }
        />
      ))}
    </div>
  )
}

export const ContractorSteps = memo(ContractorStepsComponent)
