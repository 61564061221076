import React, { memo, useCallback, useContext, useMemo, useState } from 'react'
import { CancelMinor } from '@shopify/polaris-icons'
import { MenuActionItem } from 'src/interfaces/ui'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { PaymentsContext } from '../Payments.context'
import { ActionButton } from 'src/components/shared/ActionButton'
import tw from 'twin.macro'
import { DeletePayment } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { useDispatch, useSelector } from 'react-redux'
import { getAccessInfo, getPartnerId } from 'src/selectors/user'
import { PaymentStatus } from 'src/constants/payment/enums'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'
import { showToast } from 'src/utils/toast'
import { addS } from 'src/utils/baseUtils'
import { UserPermission } from 'src/constants/user'
import { handleApiError } from 'src/utils/errorHandlers'

const PaymentsBatchActionButtonComponent = () => {
  const dispatch = useDispatch()

  const accessInfo = useSelector(getAccessInfo)

  const { selectedPayments, refreshPayments } = useContext(PaymentsContext)

  const selectedPaymentsToDelete = useMemo(
    () => selectedPayments.filter((item) => item.status === PaymentStatus.NotPaid),
    [selectedPayments],
  )

  const partnerId = useSelector(getPartnerId)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isOpen = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  const actionItems: MenuActionItem[] = useMemo(() => {
    if (
      !selectedPaymentsToDelete.length ||
      !accessInfo?.permission?.includes(UserPermission.PaymentsDelete)
    ) {
      return []
    }

    return [
      {
        label: 'Delete Payments',
        icon: <CancelMinor width={20} height={20} />,
        onClick: () => {
          if (!selectedPaymentsToDelete.length) {
            return
          }

          handleOpenConfirm()
          handleClose()
        },
      },
    ]
  }, [selectedPaymentsToDelete, accessInfo])

  const handleDeletePayment = useCallback(async () => {
    if (!selectedPaymentsToDelete.length || !partnerId) {
      return
    }

    try {
      await createAsyncAction(
        dispatch,
        DeletePayment.request({
          partnerId,
          paymentIds: selectedPaymentsToDelete.map((item) => item.id),
        }),
      )
      showToast('Payment is deleted.')
      refreshPayments()
    } catch (err) {
      handleApiError(err)
    }

    handleCloseConfirm()
  }, [selectedPaymentsToDelete, partnerId])

  return (
    <>
      <ActionButton
        className={tw`px-4`}
        selected={!!anchorEl}
        color="white"
        text="Actions"
        disabled={!selectedPaymentsToDelete.length}
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={anchorEl}
        isOpen={isOpen}
        items={actionItems}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      />
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title={`Delete ${addS(selectedPaymentsToDelete.length, 'payment')}`}
        text={`Are you sure you want to delete ${selectedPaymentsToDelete.length} ${addS(
          selectedPaymentsToDelete.length,
          'payment',
        )}?`}
        submitLabel="Delete"
        onClose={handleCloseConfirm}
        onSubmit={handleDeletePayment}
      />
    </>
  )
}

export const PaymentsBatchActionButton = memo(PaymentsBatchActionButtonComponent)
