import React, { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { CircleCancelMinor } from '@shopify/polaris-icons'
import { Tag } from 'src/interfaces/tag'
import { Typography } from '@mui/material'
import { Button } from 'src/components/shared/Button'
import { colors } from 'src/theme'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'

interface Props {
  tag: Tag
  hideSeparator?: boolean
  onRemove(tag: Tag): void | Promise<void>
}

const ManageableTagComponent = ({ tag, hideSeparator, onRemove }: Props) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  const handleRemove = useCallback(async () => {
    await onRemove(tag)

    handleCloseConfirm()
  }, [tag, onRemove])

  return (
    <div
      css={[
        tw`px-4 py-3 flex items-center justify-between`,
        !hideSeparator && tw`border-0 border-b border-solid border-[#EDEDED]`,
      ]}
    >
      <div>
        <Typography
          variant="subtitle1"
          style={tag.name.length > 20 ? { wordWrap: 'break-word' } : undefined}
          css={tw`w-[200px]`}
        >
          {tag.name}
        </Typography>
        <Typography
          variant="body2"
          color={colors.SHADES_GREY_400}
          style={(tag.description?.length || 0) > 20 ? { wordWrap: 'break-word' } : undefined}
          css={tw`w-[200px]`}
        >
          {tag.description}
        </Typography>
      </div>
      <Button
        color="red"
        size="small"
        leftIcon={<CircleCancelMinor width={20} fill={colors.COLORS_RED_500} />}
        className={tw`min-w-[36px]`}
        onClick={handleOpenConfirm}
      />
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title="Delete tag"
        text={`Are you sure you want to delete “${tag.name}”?`}
        submitLabel="Delete"
        onClose={handleCloseConfirm}
        onSubmit={handleRemove}
      />
    </div>
  )
}

export const ManageableTag = memo(ManageableTagComponent)
