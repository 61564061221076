import React, { memo, ReactNode, useMemo } from 'react'
import { Modal } from '@mui/material'
import tw from 'twin.macro'
import { ModalHeader } from 'src/components/shared/ModalHeader'

interface Props {
  children?: ReactNode
  title: string
  isOpen?: boolean
  onClose(): void
}

const InviteWorkersModalComponent = ({ title, children, isOpen, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur`}
    >
      <div css={tw`bg-white rounded-lg max-w-[656px]`}>
        <ModalHeader title={title} onClose={onClose} />
        {children}
      </div>
    </Modal>
  )
}

export const InviteWorkersModal = memo(InviteWorkersModalComponent)
