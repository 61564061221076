import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro'
import React, { memo, useEffect, useState } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { contractorColumns } from 'src/constants/contractor/contractorGrid'
import { Contractor } from 'src/interfaces/contractor'
import tw from 'twin.macro'
import { EmptyContractors } from './EmptyContractors'
import { DropdownMinor } from '@shopify/polaris-icons'
import { ExportTableButton } from 'src/components/shared/grid/ExportTableButton'
import { ContractorState } from 'src/constants/contractor/enums'

const handleGetRowId = (row: Contractor) => row.id

interface Props {
  contractors: Array<Contractor>
  selectedContractorState: ContractorState
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowCount: number
  initialSortField?: string
  onRowClick(params: { row: Contractor }): void
}

const ContractorsGridComponent = ({
  selectedContractorState,
  contractors,
  isLoading,
  rowCount,
  initialSortField,
  onRowClick,
}: Props) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: initialSortField || 'createdAt', sort: 'desc' },
  ])

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel)
  }

  useEffect(() => {
    setSortModel([{ field: initialSortField || 'createdAt', sort: 'desc' }])
  }, [initialSortField])

  return (
    <div
      css={[
        tw`w-full mt-4 h-[400px] rounded-lg`,
        {
          '@media (min-height: 830px)': tw`h-[500px]`,
          '@media (min-height: 1000px)': tw`h-[700px]`,
          '@media (min-height: 1440px)': tw`h-[1100px]`,
          '@media (min-height: 2000px)': tw`h-[1500px]`,
        },
      ]}
    >
      <GridTable
        rows={contractors}
        columns={contractorColumns[selectedContractorState]}
        loading={isLoading}
        rowCount={rowCount}
        getRowId={handleGetRowId}
        onRowClick={onRowClick}
        rowHeight={44}
        columnHeaderHeight={36}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        slots={{
          noResultsOverlay: EmptyContractors,
          noRowsOverlay: EmptyContractors,
          columnSortedAscendingIcon: () => <DropdownMinor fill="#655E7C" width={20} />,
          columnSortedDescendingIcon: () => (
            <DropdownMinor fill="#655E7C" css={tw`rotate-180`} width={20} />
          ),
          columnUnsortedIcon: () => null,
          footer: () => (
            <div
              css={tw`py-2 px-4 flex justify-end w-full bg-white border-0 border-t border-solid border-[#EDEDED]`}
            >
              <ExportTableButton />
            </div>
          ),
        }}
        css={tw`bg-white border-0`}
      />
    </div>
  )
}

export const ContractorsGrid = memo(ContractorsGridComponent)
