import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreatePartnerDriver } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { InviteWorkersViaMessageFormValues } from './InviteWorkersViaMessageForm.interface'
import { InviteWorkersViaMessageSchema } from './InviteWorkersViaMessageForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import { getMarkets } from 'src/selectors/market'
import { showToast } from 'src/utils/toast'
import { getPartnerId } from 'src/selectors/user'
import { getOnboardingTemplates } from 'src/selectors/onboarding'
import { Button } from 'src/components/shared/Button'
import { Tags } from 'src/components/tags/Tags'
import { Tag } from 'src/interfaces/tag'
import { handleApiError } from 'src/utils/errorHandlers'

const initialValues: InviteWorkersViaMessageFormValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
}

interface Props {
  onSubmit(): void
  onClose(): void
}

const PHONE_MASK = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const InviteWorkersViaMessageForm = ({ onSubmit, onClose }: Props) => {
  const dispatch = useDispatch()

  const markets = useSelector(getMarkets)
  const onboardingTemplates = useSelector(getOnboardingTemplates)
  const partnerId = useSelector(getPartnerId)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([])

  const handleFormSubmit = async ({
    collectDataTemplateId,
    ...values
  }: InviteWorkersViaMessageFormValues) => {
    if (!partnerId) {
      return
    }

    setIsLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        CreatePartnerDriver.request({
          partnerId,
          collectDataTemplateId,
          tagNames: selectedTags.map((item) => item.name),
          ...values,
        }),
      )

      showToast('Invite is sent!')

      onSubmit()
      onClose()
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectTag = useCallback((newTag: Tag) => {
    setSelectedTags((prev) => [...prev, newTag])
  }, [])

  const handleRemoveTag = useCallback((removedTag: Tag) => {
    setSelectedTags((prev) => [...prev.filter((item) => item.name !== removedTag.name)])
  }, [])

  return (
    <Formik
      initialValues={{
        ...initialValues,
        partnerMarketId: markets?.length === 1 ? markets[0].id : undefined,
        collectDataTemplateId:
          onboardingTemplates?.length === 1 ? onboardingTemplates[0].id : undefined,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={InviteWorkersViaMessageSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <div css={tw`px-4 pt-4`}>
            <div
              css={tw`grid grid-cols-2 gap-4 border-0 border-b border-solid border-[#EDEDED] mb-4 pb-4`}
            >
              <FormikTextField
                name="firstName"
                css={tw`w-[304px]`}
                label="First name"
                placeholder="Enter contractor's first name"
              />
              <FormikTextField
                name="lastName"
                label="Last name"
                css={tw`w-[304px]`}
                placeholder="Enter contractor's last name"
              />
              <FormikTextField
                name="phone"
                label="Phone number"
                css={tw`w-[304px]`}
                mask={PHONE_MASK}
                placeholder="Enter contractor's phone number"
              />
              <FormikTextField
                name="email"
                label="Email"
                css={tw`w-[304px]`}
                placeholder="Enter contractor's email"
              />
            </div>
            <Tags
              tags={selectedTags}
              className={tw`mb-4 pb-4 border-0 border-b border-solid border-[#EDEDED]`}
              onSelectTag={handleSelectTag}
              onRemoveTag={handleRemoveTag}
            />
            <div css={tw`grid grid-cols-2 gap-4 mb-4`}>
              <FormikSelect
                name="collectDataTemplateId"
                label="Select onboarding"
                options={onboardingTemplates.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
              <FormikSelect
                name="partnerMarketId"
                label="Select market"
                options={markets.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </div>
          </div>
          <div
            css={tw`flex flex-col justify-end items-end border-0 border-t border-solid border-[#EDEDED] mt-4 p-4 pb-3 pt-3`}
          >
            <Button
              variant="contained"
              size="small"
              loading={isLoading}
              onClick={() => handleSubmit()}
            >
              Send invite
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}
