import { Formik } from 'formik'
import { useState } from 'react'
import { CreateTagFormValues } from './CreateTagForm.interface'
import { CreateTagSchema } from './CreateTagForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { showToast } from 'src/utils/toast'
import { Button } from 'src/components/shared/Button'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { CreateTag } from 'src/constants/actionTypes'
import { Tag } from 'src/interfaces/tag'

interface Props {
  onSubmit?(values: Tag): void | Promise<void>
  onClose(): void
}

const initialValues = {
  name: '',
  description: '',
}

export const CreateTagForm = ({ onSubmit, onClose }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFormSubmit = async (values: CreateTagFormValues) => {
    setIsLoading(true)

    try {
      const tag: Tag = await createAsyncAction(dispatch, CreateTag.request(values))

      if (onSubmit) {
        onSubmit(tag)
      }
    } catch (err: any) {
      showToast(err, {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={CreateTagSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <div css={tw`px-4 pt-4`}>
            <FormikTextField
              name="name"
              css={tw`w-[304px] mb-4`}
              label="Tag name"
              placeholder="Enter tag name"
            />
            <FormikTextField
              name="description"
              css={tw`w-[304px]`}
              label="Tag description"
              placeholder="Enter tag description"
              multiline
            />
          </div>
          <div
            css={tw`flex flex-row justify-between items-end  border-0 border-t border-solid border-[#EDEDED] mt-4 p-4 pt-3`}
          >
            <Button
              variant="contained"
              loading={isLoading}
              size="small"
              color="grey"
              onClick={onClose}
            >
              Close
            </Button>
            <div css={tw`flex flex-col items-end`}>
              <Button
                variant="contained"
                size="small"
                loading={isLoading}
                onClick={() => handleSubmit()}
              >
                Create tag
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}
