import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'

export interface WarningContainerProps {
  title: string
  subtitle?: string
  className?: string
}

export const WarningContainer = ({ title, subtitle, className }: WarningContainerProps) => {
  return (
    <div css={tw`flex flex-row`} className={className}>
      <div css={tw`bg-[#FF6320] rounded-2xl w-2 mr-3`} />
      <div>
        <Typography variant="subtitle1">{title}</Typography>
        {subtitle && (
          <Typography variant="body2" color={colors.SHADES_GREY_400} css={tw`mt-1`}>
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  )
}
