import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'
import { getAuthToken } from 'src/selectors/auth'
import { SignInPage } from 'src/pages/auth/SignInPage'
import { SecureHooksContainer } from './SecureHooksContainer'
import { ROUTES } from './constants/routes'
import { OnboardingPage } from './pages/dashboard/OnboardingPage'
import { PaymentsPage } from './pages/dashboard/PaymentsPage'
import { ManagersPage } from './pages/dashboard/ManagersPage'
import { InviteAcceptPage } from './pages/auth/InviteAcceptPage'
import { SignUpPage } from './pages/auth/SignUpPage'
import { DashboardPage } from './pages/dashboard/DashboardPage'
import { ForgotPasswordPage } from './pages/auth/ForgotPasswordPage'
import { ForgotPasswordLinkSentPage } from './pages/auth/ForgotPasswordLinkSentPage'
import { ResetPasswordPage } from './pages/auth/ResetPasswordPage'
import { SignInAsPage } from './pages/auth/SignInAsPage'
import { ProtectedWithPermission } from './components/shared/ProtectedWithPermission'
import { UserPermission } from './constants/user'
import { NavigateToInitialRoute } from './components/layout/NavigateToInitialRoute'
import { ContractorsPage } from './pages/dashboard/ContractorsPage'
import { ActivitiesDetailsModal } from './components/activities/ActivitiesDetailsModal'

export const AppRoutes = () => {
  const authToken = useSelector(getAuthToken)

  return (
    <BrowserRouter>
      <Routes>
        {authToken ? (
          <>
            <Route path={ROUTES.BASE_URL} element={<DashboardPage />}>
              <Route index element={<NavigateToInitialRoute />} />
              <Route
                path={ROUTES.ONBOARDING_URL}
                element={
                  <ProtectedWithPermission withNavigate permission={UserPermission.OnboardingView}>
                    <OnboardingPage />
                  </ProtectedWithPermission>
                }
              />
              <Route
                path={ROUTES.PAYMENTS_URL}
                element={
                  <ProtectedWithPermission withNavigate permission={UserPermission.PaymentsView}>
                    <PaymentsPage />
                  </ProtectedWithPermission>
                }
              />
              <Route
                path={ROUTES.CONTRACTORS_URL}
                element={
                  // <ProtectedWithPermission withNavigate permission={UserPermission.PaymentsView}>
                  <ContractorsPage />
                  // </ProtectedWithPermission>
                }
              />
              <Route path={ROUTES.MANAGERS_URL} element={<ManagersPage />} />
              <Route path="*" element={<Navigate to={ROUTES.ONBOARDING_URL} />} />
            </Route>
            <Route path={ROUTES.SIGN_IN_AS_URL} element={<SignInAsPage />} />
          </>
        ) : (
          <>
            <Route path={ROUTES.INVITE_ACCEPT_URL} element={<InviteAcceptPage />} />
            <Route path={ROUTES.SIGN_UP_URL} element={<SignUpPage />} />
            <Route path={ROUTES.SIGN_IN_URL} element={<SignInPage />} />
            <Route path={ROUTES.SIGN_IN_AS_URL} element={<SignInAsPage />} />
            <Route path={ROUTES.FORGOT_PASSWORD_URL} element={<ForgotPasswordPage />} />
            <Route
              path={ROUTES.FORGOT_PASSWORD_LINK_SENT_URL}
              element={<ForgotPasswordLinkSentPage />}
            />
            <Route path={ROUTES.RESET_PASSWORD_URL} element={<ResetPasswordPage />} />
            <Route path="*" element={<Navigate to={ROUTES.SIGN_IN_URL} />} />
          </>
        )}
      </Routes>
      {authToken && <SecureHooksContainer />}
      <ActivitiesDetailsModal />
    </BrowserRouter>
  )
}
