import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import React, { memo } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { paymentColumns } from 'src/constants/payment/paymentGrid'
import { Payment } from 'src/interfaces/payment'
import tw from 'twin.macro'
import { EmptyPayments } from './EmptyPayments'
import { DropdownMinor } from '@shopify/polaris-icons'
import { ExportTableButton } from 'src/components/shared/grid/ExportTableButton'

const handleGetRowId = (row: Payment) => row.id

interface Props {
  payments: Array<Payment>
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowCount: number
  rowSelectionModel: GridRowSelectionModel
  onPaginationModelChange(paginationModel: GridPaginationModel): void
  onRowSelectionModelChange(rowSelectionModel: GridRowSelectionModel): void
}

const PaymentsGridComponent = ({
  payments,
  isLoading,
  // paginationModel,
  // pageSizeOptions,
  rowCount,
  rowSelectionModel,
  // onPaginationModelChange,
  onRowSelectionModelChange,
}: Props) => {
  return (
    <div
      css={[
        tw`w-full mt-4 h-[400px] rounded-lg`,
        {
          '@media (min-height: 830px)': tw`h-[500px]`,
          '@media (min-height: 1000px)': tw`h-[700px]`,
          '@media (min-height: 1440px)': tw`h-[1100px]`,
          '@media (min-height: 2000px)': tw`h-[1500px]`,
        },
      ]}
    >
      <GridTable
        checkboxSelection
        rows={payments}
        columns={paymentColumns}
        rowSelectionModel={rowSelectionModel}
        loading={isLoading}
        // pagination
        // paginationMode="server"
        // paginationModel={paginationModel}
        rowCount={rowCount}
        // onPaginationModelChange={onPaginationModelChange}
        // pageSizeOptions={pageSizeOptions}
        getRowId={handleGetRowId}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowHeight={44}
        columnHeaderHeight={36}
        initialState={{
          sorting: {
            sortModel: [{ field: 'uploadedAt', sort: 'desc' }],
          },
        }}
        slots={{
          noResultsOverlay: EmptyPayments,
          noRowsOverlay: EmptyPayments,
          columnSortedDescendingIcon: () => <DropdownMinor fill="#655E7C" width={20} />,
          columnSortedAscendingIcon: () => (
            <DropdownMinor fill="#655E7C" css={tw`rotate-180`} width={20} />
          ),
          columnUnsortedIcon: () => null,
          footer: () => (
            <div
              css={tw`py-2 px-4 flex justify-end w-full bg-white border-0 border-t border-solid border-[#EDEDED]`}
            >
              <ExportTableButton />
            </div>
          ),
        }}
        css={tw`bg-white border-0`}
      />
    </div>
  )
}

export const PaymentsGrid = memo(PaymentsGridComponent)
