import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { ActionButton } from '../shared/ActionButton'
import { ClockMinor } from '@shopify/polaris-icons'
import { Popover, Typography } from '@mui/material'
import { ActivitiesList } from '../activities/ActivitiesList'
import { useSelector } from 'react-redux'
import { DashboardsUpdatesType } from 'src/constants/activities'
import { getDashboardsUpdatesNotViewed } from 'src/selectors/activities'

const ActivitiesButtonComponent = () => {
  const disabledContractorsUpdates = useSelector(
    getDashboardsUpdatesNotViewed(DashboardsUpdatesType.DisabledContractors),
  )
  const failedPaymentsUpdates = useSelector(
    getDashboardsUpdatesNotViewed(DashboardsUpdatesType.FailedPayments),
  )
  const waitingForApprovalUpdates = useSelector(
    getDashboardsUpdatesNotViewed(DashboardsUpdatesType.WaitingForApproval),
  )

  const alertsAmount = useMemo(
    () =>
      (disabledContractorsUpdates.length ? 1 : 0) +
      (failedPaymentsUpdates.length ? 1 : 0) +
      (waitingForApprovalUpdates.length ? 1 : 0),
    [
      disabledContractorsUpdates.length,
      failedPaymentsUpdates.length,
      waitingForApprovalUpdates.length,
    ],
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <ActionButton
        selected={!!anchorEl}
        className={tw`px-4 mr-4`}
        color="dark"
        text="Activities"
        icon={
          alertsAmount ? (
            <div css={tw`w-5 h-5 bg-[#FF1A51] rounded flex items-center justify-center`}>
              <Typography color="white" variant="overline">
                {alertsAmount}
              </Typography>
            </div>
          ) : (
            <ClockMinor fill="white" width={20} />
          )
        }
        onClick={handleClick}
      />
      <Popover
        anchorEl={anchorEl}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      >
        {isMenuOpen ? <ActivitiesList onItemClick={handleCloseMenu} /> : null}
      </Popover>
    </>
  )
}

export const ActivitiesButton = memo(ActivitiesButtonComponent)
