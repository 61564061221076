import { createActions } from 'src/utils/reduxUtils'

export const SignIn = createActions('SignIn')
export const SignUp = createActions('SignUp')
export const ForgotPassword = createActions('ForgotPassword')
export const ResetPassword = createActions('ResetPassword')
export const FetchInvite = createActions('FetchInvite')
export const SetAuthTokens = createActions('SetAuthTokens')
export const Logout = createActions('Logout')

export const FetchUser = createActions('FetchUser')

export const FetchMarkets = createActions('FetchMarkets')
export const FetchOnboardingTemplates = createActions('FetchOnboardingTemplates')
export const SetSelectedMarket = createActions('SetSelectedMarket')

export const FetchOnboardingsCounts = createActions('FetchOnboardingsCounts')
export const FetchOnboardings = createActions('FetchOnboardings')
export const FetchOnboarding = createActions('FetchOnboarding')
export const FetchRecollectingSteps = createActions('FetchRecollectingSteps')
export const SendRemainderOnboarding = createActions('SendRemainderOnboarding')
export const SendRemainderContractor = createActions('SendRemainderContractors')

export const FetchPayments = createActions('FetchPayments')
export const UploadPaymentsCSV = createActions('UploadPaymentsCSV')
export const FetchPaymentInvoice = createActions('FetchPaymentInvoice')
export const DeletePayment = createActions('DeletePayment')
export const ProcessPayments = createActions('ProcessPayments')

export const FetchContractors = createActions('FetchContractors')
export const FetchContractorsCounts = createActions('FetchContractorsCounts')
export const FetchContractor = createActions('FetchContractor')

export const InviteWorkersViaCSV = createActions('InviteWorkersViaCSV')

export const UploadPartnerDriversCSV = createActions('UploadPartnerDriversCSV')
export const CreatePartnerDriver = createActions('CreatePartnerDriver')
export const UpdatePartnerDriver = createActions('UpdatePartnerDriver')
export const ActivatePartnerDriver = createActions('ActivatePartnerDriver')
export const DeactivatePartnerDriver = createActions('DeactivatePartnerDriver')

export const CreateTag = createActions('CreateTag')
export const FetchTags = createActions('FetchTags')
export const DeleteTag = createActions('DeleteTag')
export const DriverAddTag = createActions('DriverAddTag')
export const DriverDeleteTag = createActions('DriverDeleteTag')

export const RejectOnboarding = createActions('RejectOnboarding')
export const UnrejectOnboarding = createActions('UnrejectOnboarding')

export const ApproveOnboardingStep = createActions('ApproveStep')
export const RecollectOnboardingStep = createActions('RecollectStep')

export const SetActivityDetails = createActions('SetActivityDetails')

export const ValidateSinglePayment = createActions('ValidateSinglePayment')
export const AddSinglePayment = createActions('AddSinglePayment')

export const FetchDashboardsUpdates = createActions('FetchDashboardsUpdates')
export const ViewDashboardsUpdates = createActions('ViewDashboardsUpdates')
export const SetDashboardsUpdates = createActions('SetDashboardsUpdates')
