import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ForgotPassword } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { ForgotPasswordFormValues } from './ForgotPasswordForm.interface'
import { ForgotPasswordSchema } from './ForgotPasswordForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'

interface Props {
  onSuccess(): void
}

const initialValues: ForgotPasswordFormValues = {
  email: '',
}

export const ForgotPasswordForm = ({ onSuccess }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFormSubmit = async (values: ForgotPasswordFormValues) => {
    setIsLoading(true)

    try {
      await createAsyncAction(dispatch, ForgotPassword.request(values))
      showToast('Email is sent!')
      onSuccess()
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={ForgotPasswordSchema}
    >
      {({ handleSubmit }) => (
        <div css={tw`w-full`}>
          <FormikTextField
            name="email"
            type="email"
            label="Email"
            css={tw`w-full mb-6`}
            placeholder="Enter your email address"
          />
          <Button
            className={tw`w-full`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Reset password
          </Button>
        </div>
      )}
    </Formik>
  )
}
