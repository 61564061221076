import _ from 'lodash'

export const filterChangedValues = <T extends Record<string, any>>(
  oldObject: Record<string, any>,
  newObject: T,
): Partial<T> => _.pickBy<T>(newObject, (value, key) => value !== oldObject[key])

export const addS = (count: number, text: string): string => {
  if (count <= 1) {
    return text
  }

  return `${text}s`
}

export const addSForString = (text: string): string => {
  if (text[text.length - 1] === 's') {
    return `${text}'`
  }

  return `${text}'s`
}

export const cutLongWord = (word: string, charsLimit = 20): string => {
  return word.length > charsLimit ? `${word.slice(0, charsLimit)}...` : word
}

export const promiseWithMinimalTime = <T = any>(promise: Promise<T>, executionTime = 3000) => {
  return Promise.all([
    promise,
    new Promise((res) => {
      setTimeout(() => {
        res(null)
      }, executionTime)
    }),
  ])
}
