import React, { memo, useCallback, useContext, MouseEvent } from 'react'
import tw from 'twin.macro'
import { HorizontalDotsMinor } from '@shopify/polaris-icons'
import { PaymentsContext } from '../Payments.context'
import { Payment } from 'src/interfaces/payment'
import { Button } from 'src/components/shared/Button'

interface Props {
  payment: Payment
  disabled?: boolean
}

const PaymentsItemActionButtonComponent = ({ payment, disabled }: Props) => {
  const { openPaymentsItemAction } = useContext(PaymentsContext)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      openPaymentsItemAction(event, payment)
    },
    [payment],
  )

  return (
    <Button
      className={tw`px-2 rounded h-[28px]`}
      color="grey"
      size="small"
      disabled={disabled}
      leftIcon={<HorizontalDotsMinor width={20} height={20} />}
      onClick={handleClick}
    />
  )
}

export const PaymentsItemActionButton = memo(PaymentsItemActionButtonComponent)
