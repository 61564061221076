import { AccessInfo, User } from 'src/interfaces/user'
import { State } from '../reducers/types'
import { Partner } from 'src/interfaces/partner'

export const getUser = (state: State): User | null => state.user.user

export const getAccessInfo = (state: State): AccessInfo | null => state.user.accessInfo

export const getIsUserInitialized = (state: State): boolean => state.user.isUserInitialized

export const getPartnerId = (state: State): string | null => {
  const user = getUser(state)

  if (!user) {
    return null
  }

  return user.partner.id
}

export const getPartner = (state: State): Partner | null => {
  const user = getUser(state)

  if (!user) {
    return null
  }

  return user.partner
}
