import { put, takeEvery, call } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  ApproveOnboardingStep,
  RejectOnboarding,
  RecollectOnboardingStep,
  UnrejectOnboarding,
} from 'src/constants/actionTypes'
import { Endpoints } from 'src/constants/endpoints'
import { ApiService, callSecureApi } from '../api/callApi'
import { CollectDataStepDeclineReason } from 'src/constants/collectedData'

interface RejectOnboardingRequest {
  onboardingId: string
}

function* onRejectOnboarding(action: AsyncAction<RejectOnboardingRequest, any>) {
  const { onboardingId } = action.payload

  try {
    const { payload }: { payload: any } = yield call(callSecureApi, Endpoints.RejectOnboarding, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        onboardingId,
      },
    })

    yield put(RejectOnboarding.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to reject onboarding'

    yield put(RejectOnboarding.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UnrejectOnboardingRequest {
  onboardingId: string
}

function* onUnrejectOnboarding(action: AsyncAction<UnrejectOnboardingRequest, any>) {
  const { onboardingId } = action.payload

  try {
    const { payload }: { payload: any } = yield call(callSecureApi, Endpoints.UnrejectOnboarding, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        onboardingId,
      },
    })

    yield put(UnrejectOnboarding.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to unreject onboarding'

    yield put(UnrejectOnboarding.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ApproveOnboardingStepRequest {
  stepId: string
  partnerDriverId: string
}

function* onApproveOnboardingStep(action: AsyncAction<ApproveOnboardingStepRequest, any>) {
  const { stepId, partnerDriverId } = action.payload

  try {
    const { payload }: { payload: any } = yield call(
      callSecureApi,
      Endpoints.ApproveOnboardingStep,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        body: {
          stepId,
          partnerDriverId,
        },
      },
    )

    yield put(ApproveOnboardingStep.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to approve onboarding step'

    yield put(ApproveOnboardingStep.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface RecollectOnboardingStepRequest {
  stepId: string
  partnerDriverId: string
  email: string
}

function* onRecollectOnboardingStep(action: AsyncAction<RecollectOnboardingStepRequest, any>) {
  const { stepId, partnerDriverId, email } = action.payload

  try {
    const { payload }: { payload: any } = yield call(
      callSecureApi,
      Endpoints.RecollectOnboardingStep,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        body: {
          stepId,
          partnerDriverId,
          stepMetadata: {
            declineReason: CollectDataStepDeclineReason.DeclinedByCustomer,
            reviewedBy: email,
          },
        },
      },
    )

    yield put(RecollectOnboardingStep.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to approve onboarding step'

    yield put(RecollectOnboardingStep.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingActionsSaga() {
  yield takeEvery(RejectOnboarding.type.REQUEST, onRejectOnboarding)
  yield takeEvery(UnrejectOnboarding.type.REQUEST, onUnrejectOnboarding)
  yield takeEvery(ApproveOnboardingStep.type.REQUEST, onApproveOnboardingStep)
  yield takeEvery(RecollectOnboardingStep.type.REQUEST, onRecollectOnboardingStep)
}
