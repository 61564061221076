import { Typography, SvgIcon, CircularProgress } from '@mui/material'
import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react'
import { colors } from 'src/theme'
import tw, { css } from 'twin.macro'
import { LandingPageMajor } from '@shopify/polaris-icons'
import { showToast } from 'src/utils/toast'

interface Props {
  acceptTypes: Array<string>
  isLoading?: boolean
  fileSizeLimit?: number
  onSelect: (file: File) => void
}

// TODO: make default file input from this component instead of CSV input
const FileUploadInputComponent = ({ acceptTypes, isLoading, fileSizeLimit, onSelect }: Props) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false)

  const onInputImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0]

        if (fileSizeLimit && file.size > fileSizeLimit) {
          e.target.value = ''

          showToast(`File size is bigger then ${fileSizeLimit / 1000000}MB`, {
            variant: 'error',
          })

          return
        }

        if (!acceptTypes.includes(file.type)) {
          e.target.value = ''

          showToast('File type is not supported', {
            variant: 'error',
          })

          return
        }

        onSelect(file)
      }
    },
    [onSelect],
  )

  const handleDragEnter = useCallback(() => {
    setIsDragOver(true)
  }, [])

  const handleDragLeave = useCallback(() => {
    setIsDragOver(false)
  }, [])

  const inputAcceptTypes = useMemo(() => acceptTypes.join(','), [acceptTypes])

  return (
    <>
      <label htmlFor="banner-file">
        <div
          css={[
            tw`relative border-[#EDEDED] border rounded-lg border-dashed px-4 py-8 flex flex-1 flex-col justify-center items-center transition duration-300 ease-linear`,
            styles.container(isDragOver),
          ]}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <SvgIcon css={tw`w-5 h-5`}>
                <LandingPageMajor color={colors.BASICS_BLACK} />
              </SvgIcon>
              <Typography css={tw`my-2`} component="span">
                <Typography css={tw`underline`} color={colors.BRAND_BLUE} component="span">
                  Tap to upload
                </Typography>{' '}
                or drag and drop
              </Typography>
              <Typography variant="body2" color={colors.GRAY_COOL_GRAY}>
                CSV (max. 10MB)
              </Typography>
              <input
                type="file"
                id="banner-file"
                name="file"
                accept={inputAcceptTypes}
                onChange={onInputImage}
                css={styles.input}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
              />
            </>
          )}
        </div>
      </label>
      <input
        type="file"
        id="banner-file"
        name="file"
        accept={inputAcceptTypes}
        onChange={onInputImage}
        hidden
      />
    </>
  )
}

const styles = {
  container: (isDragOver?: boolean) => [
    isDragOver
      ? css`
          background-color: ${colors.GRAY_LIGHT_COOL};
          border-color: ${colors.BRAND_BLUE};
        `
      : css`
          background-color: ${colors.GRAY_WHITE};
        `,
  ],
  input: [
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 1;
      appearance: none;
      -webkit-appearance: none;
      opacity: 0;
    `,
  ],
}

export const FileUploadInput = memo(FileUploadInputComponent)
