import { memo } from 'react'
import { Chip } from 'src/components/shared/Chip'
import { Tooltip } from '@mui/material'

interface Props {
  titles: Array<string>
}

const StepsChipComponent = ({ titles }: Props) => {
  if (!titles.length) {
    return null
  }

  if (titles.length > 1) {
    return (
      <Tooltip title={titles.join(', ')} placement="bottom-start">
        <span>
          <Chip text={`${titles.length} documents`} />
        </span>
      </Tooltip>
    )
  }

  return <Chip text={titles[0]} />
}

export const StepsChip = memo(StepsChipComponent)
