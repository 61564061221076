import { Logout, SetAuthTokens } from 'src/constants/actionTypes'
import { authService } from 'src/services/AuthService'
import { Action } from 'src/utils/reduxUtils'
import { AuthState } from './types'

const getInitialState = (): AuthState => ({
  authToken: authService.getStoredAuthToken(),
  refreshToken: authService.getStoreRefreshToken(),
})

export const authReducer = (
  state: AuthState = getInitialState(),
  action: Action<any>,
): AuthState => {
  switch (action.type) {
    case SetAuthTokens.type.SUCCESS:
      return {
        ...state,
        authToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
