import { memo } from 'react'
import tw from 'twin.macro'
import { CustomersMajor, PauseCircleMajor } from '@shopify/polaris-icons'
import { ContractorTab } from './ContractorTab'
import { useSelector } from 'react-redux'
import { ContractorState } from 'src/constants/contractor/enums'
import RecollectIcon from 'src/assets/icons/recollect.svg'
import { getContractorsCounts } from 'src/selectors/contractor'
import { addS } from 'src/utils/baseUtils'

const tabs = [
  {
    state: ContractorState.Active,
    title: 'Active',
    icon: <CustomersMajor width={20} />,
  },
  {
    state: ContractorState.Deactivated,
    title: 'Deactivated',
    icon: <PauseCircleMajor width={20} />,
  },
  {
    state: ContractorState.Recollection,
    title: 'Needs Review',
    icon: <img src={RecollectIcon} css={tw`self-center`} width={20} height={20} />,
  },
]

interface Props {
  selectedTab: ContractorState
  onClick(tab: ContractorState): void
}

const ContractorTabsComponent = ({ selectedTab, onClick }: Props) => {
  const contractorCounts = useSelector(getContractorsCounts)

  return (
    <div css={tw`bg-[#EDEDED] p-1.5 rounded-lg flex gap-2 max-w-[800px]`}>
      {tabs.map((item) => (
        <ContractorTab
          title={item.title}
          key={item.title}
          state={item.state}
          icon={item.icon}
          subtitle={
            contractorCounts
              ? `${contractorCounts[item.state]} ${addS(
                  contractorCounts[item.state],
                  'contractor',
                )}`
              : ''
          }
          selected={item.state === selectedTab}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

export const ContractorTabs = memo(ContractorTabsComponent)
