export enum VehicleType {
  Sedan = 'sedan',
  Hatchback = 'hatchback',
  StationWagon = 'station_wagon',
  Suv = 'suv',
  Truck = 'truck',
  Motorcycle = 'motorcycle',
  Bicycle = 'bicycle',
  EBike = 'e_bike',
  Scooter = 'scooter',
  EScooter = 'e_scooter',
  Van = 'van',
  CargoVan = 'cargo_van',
}

export const vehicleFormTypes = [
  { label: 'Sedan', value: VehicleType.Sedan },
  { label: 'Hatchback', value: VehicleType.Hatchback },
  { label: 'Station Wagon', value: VehicleType.StationWagon },
  { label: 'Suv', value: VehicleType.Suv },
  { label: 'Truck', value: VehicleType.Truck },
  { label: 'Motorcycle', value: VehicleType.Motorcycle },
  { label: 'Bicycle', value: VehicleType.Bicycle },
  { label: 'E-Bicycle', value: VehicleType.EBike },
  { label: 'Scooter', value: VehicleType.Scooter },
  { label: 'E-Scooter', value: VehicleType.EScooter },
  { label: 'Van', value: VehicleType.Van },
  { label: 'Cargo Van', value: VehicleType.CargoVan },
]

export const nonInsuredVehicles = [
  VehicleType.Bicycle,
  VehicleType.EBike,
  VehicleType.Scooter,
  VehicleType.EScooter,
]

export const vehicleNamesMap = {
  [VehicleType.Sedan]: 'Sedan',
  [VehicleType.Hatchback]: 'Hatchback',
  [VehicleType.StationWagon]: 'Station Wagon',
  [VehicleType.Suv]: 'Suv',
  [VehicleType.Truck]: 'Truck',
  [VehicleType.Motorcycle]: 'Motorcycle',
  [VehicleType.Bicycle]: 'Bicycle',
  [VehicleType.EBike]: 'E-Bicycle',
  [VehicleType.Scooter]: 'Scooter',
  [VehicleType.EScooter]: 'E-Scooter',
  [VehicleType.Van]: 'Van',
  [VehicleType.CargoVan]: 'Cargo Van',
}
