import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction, Action } from 'src/utils/reduxUtils'
import {
  SignIn,
  SignUp,
  SetAuthTokens,
  Logout,
  ForgotPassword,
  ResetPassword,
} from 'src/constants/actionTypes'
import { ApiService, callApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { authService } from 'src/services/AuthService'

interface SignUpRequest {
  inviteId: string
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

interface SignUpPayload {
  accessToken: string
  refreshToken: string
}

function* onSignUp(action: AsyncAction<SignUpRequest, SignUpPayload>) {
  const { inviteId, firstName, lastName, email, password, confirmPassword } = action.payload

  try {
    const { payload }: { payload: SignUpPayload } = yield call(callApi, Endpoints.SignUp, {
      method: 'POST',
      body: {
        inviteId,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
      },
      apiService: ApiService.Paraworks,
    })

    yield put(SignUp.success(payload))
    yield put(SetAuthTokens.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to sign up'

    yield put(SignUp.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ForgotPasswordRequest {
  email: string
}

function* onForgotPassword(action: AsyncAction<ForgotPasswordRequest>) {
  const { email } = action.payload

  try {
    yield call(callApi, Endpoints.ForgotPassword, {
      method: 'POST',
      body: {
        email,
      },
      apiService: ApiService.Paraworks,
    })

    yield put(ForgotPassword.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to reset password'

    yield put(ForgotPassword.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface ResetPasswordRequest {
  token: string
  password: string
  confirmPassword: string
}

function* onResetPassword(action: AsyncAction<ResetPasswordRequest>) {
  const { token, password, confirmPassword } = action.payload

  try {
    yield call(callApi, Endpoints.ResetPassword, {
      method: 'POST',
      body: {
        token,
        password,
        confirmPassword,
      },
      apiService: ApiService.Paraworks,
    })

    yield put(ResetPassword.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to reset password'

    yield put(ResetPassword.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SignInRequest {
  email: string
  password: string
}

interface SignInPayload {
  accessToken: string
  refreshToken: string
}

function* onSignIn(action: AsyncAction<SignInRequest, SignInPayload>) {
  const { email, password } = action.payload

  try {
    const { payload }: { payload: SignInPayload } = yield call(callApi, Endpoints.SignIn, {
      method: 'POST',
      body: {
        password,
        email,
      },
      apiService: ApiService.Paraworks,
    })

    yield put(SignIn.success(payload))
    yield put(SetAuthTokens.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to signIn'

    yield put(SignIn.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetAuthTokens(
  action: Action<{ accessToken: string | null; refreshToken: string | null }>,
) {
  const authTokens = action.payload

  authService.setStoredAuthToken(authTokens.accessToken)
  authService.setStoredRefreshToken(authTokens.refreshToken)
}

function* onLogout() {
  yield put(
    SetAuthTokens.success({
      accessToken: null,
      refreshToken: null,
    }),
  )
  yield put(Logout.success())
}

export function* authSaga() {
  yield takeEvery(SignUp.type.REQUEST, onSignUp)
  yield takeEvery(SignIn.type.REQUEST, onSignIn)
  yield takeEvery(ForgotPassword.type.REQUEST, onForgotPassword)
  yield takeEvery(ResetPassword.type.REQUEST, onResetPassword)
  yield takeEvery(SetAuthTokens.type.SUCCESS, onSetAuthTokens)
  yield takeEvery(Logout.type.REQUEST, onLogout)
}
