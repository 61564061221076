import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { AddTagButton } from './AddTagButton'
import { Tag } from 'src/interfaces/tag'
import { RemovableTag } from './RemovableTag'

interface Props {
  tags: Array<Tag>
  className?: any
  onSelectTag(tag: Tag): void
  onRemoveTag(tag: Tag): void
}

const TagsComponent = ({ tags, className, onSelectTag, onRemoveTag }: Props) => {
  return (
    <div css={[className]}>
      <div css={tw`w-full`}>
        <Typography css={tw`mb-1.5`} color={colors.SHADES_GREY_400} variant="h6">
          Tags
        </Typography>
        <div css={tw`flex flex-wrap gap-2`}>
          <AddTagButton selectedTags={tags} onSelectTag={onSelectTag} />
          {tags.map((item) => (
            <RemovableTag key={item.name} tag={item} onRemove={onRemoveTag} />
          ))}
        </div>
      </div>
    </div>
  )
}

export const Tags = memo(TagsComponent)
