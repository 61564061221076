import React, { memo, useEffect } from 'react'
import { LoadingPage } from '../LoadingPage'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { SetAuthTokens } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { useQuery } from 'src/utils/hooks/useQuery'
import { useFetchUserAndData } from 'src/hooks/useFetchUserAndData'

const SignInAsPageComponent = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const navigate = useNavigate()

  const { fetchUserAndMarkets } = useFetchUserAndData()

  const token = query.get('token')
  const refreshToken = query.get('refreshtoken')

  useEffect(() => {
    if (token && refreshToken) {
      dispatch(
        SetAuthTokens.success({
          accessToken: token,
          refreshToken,
        }),
      )

      fetchUserAndMarkets()
    }

    navigate(ROUTES.BASE_URL)
  }, [])

  return <LoadingPage />
}

export const SignInAsPage = memo(SignInAsPageComponent)
