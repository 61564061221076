import React, { ReactNode } from 'react'
import { UserPermission } from 'src/constants/user'
import { useSelector } from 'react-redux'
import { getAccessInfo } from 'src/selectors/user'
import { NavigateToInitialRoute } from '../layout/NavigateToInitialRoute'

interface Props {
  permission: UserPermission
  children?: ReactNode
  withNavigate?: boolean
}

export const ProtectedWithPermission = ({ permission, children, withNavigate }: Props) => {
  const accessInfo = useSelector(getAccessInfo)

  if (!accessInfo?.permission?.includes(permission)) {
    return withNavigate ? <NavigateToInitialRoute /> : null
  }

  return <>{children}</>
}
