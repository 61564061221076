import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { PublicWrapper } from 'src/components/layout/PublicWrapper'
import Logo from 'src/assets/images/gigsafe-logo-black.svg'
import { Navigate, useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { ResetPasswordForm } from 'src/components/auth/reset-password-form/ResetPasswordForm'
import { useQuery } from 'src/utils/hooks/useQuery'
import { useCallback } from 'react'
import { showToast } from 'src/utils/toast'
import { GetHelp } from 'src/components/shared/GetHelp'

export const ResetPasswordPage = () => {
  const query = useQuery()
  const navigate = useNavigate()

  const token = query.get('token')

  const handleSuccess = useCallback(() => {
    navigate(ROUTES.SIGN_IN_URL)
    showToast('New password created. You can log in now.')
  }, [])

  if (!token) {
    return <Navigate to={ROUTES.SIGN_IN_URL} />
  }

  return (
    <PublicWrapper>
      <div css={tw`bg-white rounded-3xl p-4 flex pb-12`}>
        <div css={tw`w-[326px] mx-6`}>
          <div css={tw`flex items-center justify-between mt-4`}>
            <img src={Logo} />
            <GetHelp />
          </div>
          <Typography variant="h2" css={tw`mb-2 mt-16`}>
            Create new password
          </Typography>
          <Typography color="#847E96" css={tw`mt-2 mb-4 w-[280px]`}>
            Create a new password by entering it twice to proceed with using your account.
          </Typography>
          <ResetPasswordForm token={token} onSuccess={handleSuccess} />
        </div>
      </div>
    </PublicWrapper>
  )
}
