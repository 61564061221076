import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { SignIn, FetchInvite } from 'src/constants/actionTypes'
import { ApiService, callApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { InvitedUser } from 'src/interfaces/user'

interface FetchInviteRequest {
  inviteId: string
}

function* onFetchInvite(action: AsyncAction<FetchInviteRequest, InvitedUser>) {
  const { inviteId } = action.payload

  try {
    const { payload }: { payload: InvitedUser } = yield call(callApi, Endpoints.FetchInvite, {
      method: 'GET',
      params: [
        {
          field: ':inviteId',
          value: inviteId,
        },
      ],
      apiService: ApiService.Paraworks,
    })

    yield put(FetchInvite.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage =
      typeof err?.payload?.message === 'string' ? err?.payload?.message : 'Failed to fetch invite'

    yield put(SignIn.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* inviteSaga() {
  yield takeEvery(FetchInvite.type.REQUEST, onFetchInvite)
}
