import { useFormikContext } from 'formik'
import {
  BasicAutocomplete,
  BasicAutocompleteOption,
  BasicAutocompleteProps,
} from '../BasicAutocomplete'

interface Props<T> extends BasicAutocompleteProps<T> {
  name: keyof T
  label: string
  options: Array<BasicAutocompleteOption>
  onChange?(value: any): void
}

export const FormikAutocomplete = <T extends unknown>({
  name,
  label,
  options,
  onChange,
  ...rest
}: Props<T>) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<T>()

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  return (
    <BasicAutocomplete
      name={name as string}
      onBlur={handleBlur(name as string)}
      error={Boolean(isTouched && error)}
      helperText={isTouched && error}
      label={label}
      options={options}
      {...rest}
      onChange={onChange || handleChange(name)}
      value={values[name] as any}
    />
  )
}
