import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { ContractorOnboardingStatus } from 'src/constants/contractor/enums'

interface Props {
  status: ContractorOnboardingStatus
  className?: any
}

const createdLabel = {
  textStyle: tw`text-[#FF6320]`,
  containerStyle: tw`bg-[#FFEED6]`,
  text: 'Onboarding pending',
}

const stepsLabelMap = {
  [ContractorOnboardingStatus.WaitingForApproval]: {
    textStyle: tw`text-[#FF6320]`,
    containerStyle: tw`bg-[#FFEED6]`,
    text: 'Waiting for approval',
  },
  [ContractorOnboardingStatus.Completed]: {
    textStyle: tw`text-[#03B620]`,
    containerStyle: tw`bg-[#E6F9E9]`,
    text: 'Completed',
  },
  [ContractorOnboardingStatus.Created]: createdLabel,
  [ContractorOnboardingStatus.PartiallyCompleted]: createdLabel,
  [ContractorOnboardingStatus.Failed]: {
    textStyle: tw`text-[#FF1A51]`,
    containerStyle: tw`bg-[#FFE8EE]`,
    text: 'Onboarding failed',
  },
}

const ContractorStepStatusLabelComponent = ({ status, className }: Props) => {
  const params = stepsLabelMap[status]

  if (!params) {
    return null
  }

  return (
    <div css={[tw`px-2 py-1 rounded-[10px]`, params.containerStyle, className]}>
      <Typography css={params.textStyle} variant="subtitle2">
        {params.text}
      </Typography>
    </div>
  )
}

export const ContractorStepStatusLabel = memo(ContractorStepStatusLabelComponent)
