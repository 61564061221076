import { memo, ReactNode, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Button } from './Button'
import { BehaviorMajor } from '@shopify/polaris-icons'
import { FileModal } from './FileModal'

interface Props {
  className?: any
  previewContent: ReactNode
  content: ReactNode
  fullScreen?: boolean
}

const FileWithPreviewComponent = ({ className, content, previewContent, fullScreen }: Props) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false)

  const openPreview = useCallback(() => {
    setIsPreviewOpen(true)
  }, [])

  const closePreview = useCallback(() => {
    setIsPreviewOpen(false)
  }, [])

  return (
    <div css={[tw`relative inline-block overflow-hidden w-full`, className]}>
      {previewContent}
      <div css={tw`absolute top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)]`}>
        <div css={tw`[transform: translate(-50%, -50%)] top-1/2 left-1/2 absolute`}>
          <Button
            size="small"
            color="grey"
            rightIcon={<BehaviorMajor width={20} />}
            onClick={openPreview}
          >
            Open file
          </Button>
        </div>
      </div>
      <FileModal
        fullScreen={fullScreen}
        content={content}
        isOpen={isPreviewOpen}
        onClose={closePreview}
      />
    </div>
  )
}

export const FileWithPreview = memo(FileWithPreviewComponent)
