export const processPaymentCsvErrors = (csvErrors: Array<string>): Array<string> => {
  const result: Array<string> = []
  const errorLinesIndexes: Array<number> = []

  csvErrors.forEach((error, index) => {
    if (error.includes('Error at line')) {
      errorLinesIndexes.push(index)
    }
  })

  for (let idx = 0; idx < errorLinesIndexes.length; idx += 1) {
    const currentLineIndex = errorLinesIndexes[idx]

    if (idx === errorLinesIndexes.length - 1) {
      result.push(csvErrors.slice(currentLineIndex).join('. '))
      continue
    }

    const nextLineIndex = errorLinesIndexes[idx + 1]
    result.push(csvErrors.slice(currentLineIndex, nextLineIndex).join('. '))
  }

  return result
}
