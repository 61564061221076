import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { PublicWrapper } from 'src/components/layout/PublicWrapper'
import { SignInForm } from 'src/components/auth/sign-in-form/SignInForm'
import Logo from 'src/assets/images/gigsafe-logo-black.svg'
import Banner from 'src/assets/images/login-banner.svg'
import { Link } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { GetHelp } from 'src/components/shared/GetHelp'

export const SignInPage = () => {
  return (
    <PublicWrapper>
      <div css={tw`bg-white rounded-3xl p-4 flex`}>
        <div css={tw`w-[326px] ml-6 mr-10 flex flex-col justify-between`}>
          <div css={tw`flex items-center justify-between mt-4`}>
            <img src={Logo} />
            <GetHelp />
          </div>
          <div>
            <Typography variant="h2" css={tw`mb-2 mt-auto`}>
              Login
            </Typography>
            <Typography color="#847E96" css={tw`mt-2 mb-4 w-[280px]`}>
              Securely log in with your login and password to manage your fleet.
            </Typography>
            <SignInForm />
            <Link css={tw`no-underline self-center`} to={ROUTES.FORGOT_PASSWORD_URL}>
              <Typography
                css={tw`font-semibold mt-4 mb-8 text-center`}
                color={colors.PRIMARY_PURPLE_500}
              >
                Forgot password?
              </Typography>
            </Link>
          </div>
        </div>
        <img css={tw`rounded-2xl`} src={Banner} />
      </div>
    </PublicWrapper>
  )
}
