import { memo } from 'react'
import { Chip } from 'src/components/shared/Chip'
import { ContractorStatus } from 'src/constants/contractor/enums'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  status: ContractorStatus
}

export const contractorsTitles = {
  [ContractorStatus.Active]: 'Active',
  [ContractorStatus.Deactivated]: 'Deactivated',
  [ContractorStatus.ExpiresSoon]: 'Expires Soon',
}

const contractorStatusMap = {
  [ContractorStatus.Active]: {
    color: colors.COLORS_GREEN_500,
    text: contractorsTitles[ContractorStatus.Active],
    bgStyle: tw`bg-[rgba(230, 249, 233, 1)]`,
  },
  [ContractorStatus.Deactivated]: {
    color: colors.COLORS_RED_500,
    text: contractorsTitles[ContractorStatus.Deactivated],
    bgStyle: tw`bg-[rgba(255, 232, 238, 1)]`,
  },
  [ContractorStatus.ExpiresSoon]: {
    color: colors.COLORS_RED_500,
    text: contractorsTitles[ContractorStatus.ExpiresSoon],
    bgStyle: tw`bg-[rgba(255, 232, 238, 1)]`,
  },
}

const ContractorStatusChipComponent = ({ status }: Props) => (
  <Chip
    className={contractorStatusMap[status].bgStyle}
    textColor={contractorStatusMap[status].color}
    text={contractorStatusMap[status].text}
  />
)

export const ContractorStatusChip = memo(ContractorStatusChipComponent)
