import { call, put, takeEvery } from 'redux-saga/effects'
import { ActivatePartnerDriver, DeactivatePartnerDriver } from 'src/constants/actionTypes'
import { AsyncAction } from 'src/utils/reduxUtils'
import { ApiService, callSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface ActivatePartnerDriverRequest {
  partnerDriverId: string
}

function* onActivatePartnerDriver(action: AsyncAction<ActivatePartnerDriverRequest>) {
  const { partnerDriverId } = action.payload

  try {
    const { payload }: { payload: { id: string } } = yield call(
      callSecureApi,
      Endpoints.ActivatePartnerDriver,
      {
        method: 'PUT',
        apiService: ApiService.Paraworks,
        params: [
          {
            field: ':partnerDriverId',
            value: partnerDriverId,
          },
        ],
      },
    )

    yield put(ActivatePartnerDriver.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to activate partner driver'

    yield put(ActivatePartnerDriver.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface DeactivatePartnerDriverRequest {
  partnerDriverId: string
}

function* onDeactivatePartnerDriver(action: AsyncAction<DeactivatePartnerDriverRequest>) {
  const { partnerDriverId } = action.payload

  try {
    const { payload }: { payload: { id: string } } = yield call(
      callSecureApi,
      Endpoints.DeactivatePartnerDriver,
      {
        method: 'PUT',
        apiService: ApiService.Paraworks,
        params: [
          {
            field: ':partnerDriverId',
            value: partnerDriverId,
          },
        ],
      },
    )

    yield put(DeactivatePartnerDriver.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to deactivate partner driver'

    yield put(DeactivatePartnerDriver.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingManageDriversSaga() {
  yield takeEvery(ActivatePartnerDriver.type.REQUEST, onActivatePartnerDriver)
  yield takeEvery(DeactivatePartnerDriver.type.REQUEST, onDeactivatePartnerDriver)
}
