import { Logout, FetchInvite, FetchUser } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { InviteState } from './types'

const getInitialState = (): InviteState => ({
  invite: null,
})

export const inviteReducer = (
  state: InviteState = getInitialState(),
  action: Action<any>,
): InviteState => {
  switch (action.type) {
    case FetchInvite.type.SUCCESS:
      return {
        ...state,
        invite: action.payload,
      }
    case Logout.type.SUCCESS:
    case FetchUser.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
