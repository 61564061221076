import { memo, useCallback, useMemo, useState } from 'react'
import { Chip } from 'src/components/shared/Chip'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { ClockMajor, SendMajor } from '@shopify/polaris-icons'
import { DataRow } from 'src/components/shared/DataRow'
import { ContractorDetails } from 'src/interfaces/contractor'
import { DateTime } from 'luxon'
import { addS } from 'src/utils/baseUtils'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { Button } from '../shared/Button'
import { colors } from 'src/theme'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { SendRemainderOnboarding } from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'

interface Props {
  contractorDetails: ContractorDetails
}

const ContractorPendingStepComponent = ({ contractorDetails }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const lastUpdateIn = useMemo(() => {
    return Math.round(
      DateTime.now().diff(
        DateTime.fromMillis((contractorDetails.updatedAt || contractorDetails.createdAt) * 1000),
        'days',
      ).days,
    )
  }, [contractorDetails.createdAt, contractorDetails.updatedAt])

  const handleResendInvite = useCallback(async () => {
    setIsLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        SendRemainderOnboarding.request({
          partnerDriverId: contractorDetails.id,
        }),
      )

      showToast("The reminder has been resent to the contractor's email address and phone number!")
    } catch (err) {
      handleApiError(err)
    }

    setIsLoading(false)
  }, [])

  return (
    <div css={tw`bg-white rounded-lg mb-6`}>
      <div css={tw`flex flex-col justify-between`}>
        <div css={tw`flex items-center py-3 px-4`}>
          <ClockMajor width={20} />
          <Typography css={tw`ml-3`} variant="subtitle1">
            Onboarding pending
          </Typography>
        </div>
        <DataRow
          title="Date of invitation"
          text={DateTime.fromMillis(contractorDetails.createdAt * 1000).toFormat('LLL dd, yyyy')}
        />
        <DataRow
          title="Last activity"
          text={lastUpdateIn ? `${lastUpdateIn} ${addS(lastUpdateIn, 'day')} ago` : 'Today'}
        />
        <DataRow
          title="Step(s) completed"
          component={
            <Chip
              text={`${
                contractorDetails.collectedData.filter(
                  (item) => item.status !== CollectedDataStatus.Created,
                ).length
              } of ${contractorDetails.collectedData.length}`}
            />
          }
        />
      </div>
      <Button
        rightIcon={<SendMajor fill={colors.PRIMARY_PURPLE_500} width={20} height={20} />}
        className={tw`mx-4 my-3`}
        color="light"
        size="small"
        onClick={handleResendInvite}
        loading={isLoading}
      >
        Resend an invite
      </Button>
    </div>
  )
}

export const ContractorPendingStep = memo(ContractorPendingStepComponent)
