import { Formik } from 'formik'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { InviteWorkersViaLinkFormValues } from './InviteWorkersViaLinkForm.interface'
import { InviteWorkersViaLinkSchema } from './InviteWorkersViaLinkForm.validator'
import tw from 'twin.macro'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import { getMarkets } from 'src/selectors/market'
import { BasicTextField } from 'src/components/shared/form/BasicTextField'
import { showToast } from 'src/utils/toast'
import { getPartner } from 'src/selectors/user'
import { getOnboardingTemplates } from 'src/selectors/onboarding'
import { Button } from 'src/components/shared/Button'
import { handleApiError } from 'src/utils/errorHandlers'

export const InviteWorkersViaLinkForm = () => {
  const markets = useSelector(getMarkets)
  const onboardingTemplates = useSelector(getOnboardingTemplates)
  const partner = useSelector(getPartner)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inviteLink, setInviteLink] = useState<string | null>(null)

  const handleFormSubmit = async (values: InviteWorkersViaLinkFormValues) => {
    if (!partner) {
      return
    }

    setIsLoading(true)

    try {
      const generatedLink = `${process.env.REACT_APP_ONBOARDING_CLIENT_URL}/onboarding/${partner.name}/sign-up?partnerMarketId=${values.partnerMarketId}&onboardingTemplateId=${values.collectDataTemplateId}`

      setInviteLink(generatedLink)
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCopyInviteLink = useCallback(() => {
    if (!inviteLink) {
      return
    }

    navigator.clipboard.writeText(inviteLink)

    showToast('Copied!')
  }, [inviteLink])

  if (inviteLink) {
    return (
      <div>
        <div css={tw`px-4 pt-4`}>
          <BasicTextField
            css={tw`w-[304px]`}
            name="inviteLink"
            disabled
            multiline
            label="Invitation link"
            value={inviteLink}
          />
        </div>
        <div
          css={tw`flex flex-col justify-end items-end border-0 border-t border-solid border-[#EDEDED] mt-4 p-4 pt-3`}
        >
          <Button variant="contained" size="small" onClick={handleCopyInviteLink}>
            Copy to clipboard
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Formik
      initialValues={{
        partnerMarketId: markets?.length === 1 ? markets[0].id : undefined,
        collectDataTemplateId:
          onboardingTemplates?.length === 1 ? onboardingTemplates[0].id : undefined,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={InviteWorkersViaLinkSchema}
    >
      {({ handleSubmit }) => (
        <div>
          <div css={tw`px-4 pt-4`}>
            <div css={tw`w-[304px] border-0 border-b border-solid border-[#EDEDED] mb-4 pb-4`}>
              <FormikSelect
                name="collectDataTemplateId"
                label="Select onboarding"
                options={onboardingTemplates.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </div>
            <div css={tw`w-[304px] mb-4`}>
              <FormikSelect
                name="partnerMarketId"
                label="Select market"
                options={markets.map((item) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </div>
          </div>
          <div
            css={tw`flex flex-col justify-end items-end border-0 border-t border-solid border-[#EDEDED] mt-4 p-4 pt-3`}
          >
            <Button
              variant="contained"
              size="small"
              loading={isLoading}
              onClick={() => handleSubmit()}
            >
              Generate link
            </Button>
          </div>
        </div>
      )}
    </Formik>
  )
}
