import { IconButton, InputAdornment, TextField } from '@mui/material'
import tw from 'twin.macro'
// import { ActionButton } from 'src/components/shared/ActionButton'
import { CircleCancelMinor, SearchMinor } from '@shopify/polaris-icons'
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { OnboardingsContext } from './Onboardings.context'
import _ from 'lodash'
import { TagSelect } from './TagsSelect'

export const OnboardingsHeader = () => {
  const { setSearchQuery, searchQuery, tagsFilter, openManageTags, setTagsFilter } =
    useContext(OnboardingsContext)

  const searchDebounce = useRef(_.debounce(setSearchQuery, 700))

  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }, [])

  useEffect(() => {
    searchDebounce.current(searchValue)
  }, [searchValue])

  const handleClear = useCallback(() => {
    setSearchValue('')
    setSearchQuery('')
  }, [])

  return (
    <div css={tw`flex flex-row items-center gap-4 mt-6`}>
      <div css={tw`bg-white flex rounded-lg flex-row overflow-hidden w-[400px]`}>
        <TextField
          placeholder="Search"
          type="text"
          variant="outlined"
          fullWidth
          value={searchValue}
          size="small"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchMinor width={20} />
              </InputAdornment>
            ),

            endAdornment: searchQuery && (
              <InputAdornment position="end">
                <IconButton css={tw`mr-[-12px] rounded-none`} onClick={handleClear}>
                  <CircleCancelMinor width={20} fill="#B4AFC0" />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              '&:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
          }}
        />
        {/* <ActionButton
          text="Search by: Whole table"
          color="white"
          className={tw`bg-white hover:bg-[#FAFAFA] rounded-none`}
        /> */}
      </div>
      <TagSelect
        selectedTags={tagsFilter}
        setSelectedTags={setTagsFilter}
        openManageTags={openManageTags}
      />
    </div>
  )
}
