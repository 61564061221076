import React, { memo, useMemo } from 'react'
import { Modal } from '@mui/material'
import tw from 'twin.macro'
import { ModalHeader } from 'src/components/shared/ModalHeader'
import { AddSinglePaymentForm } from './add-single-payment-form/AddSinglePaymentForm'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onSubmit: () => void
}

const AddSinglePaymentModalComponent = ({ isOpen, onClose, onSubmit }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur`}
    >
      <div css={tw`bg-[#F6F6F6] rounded-lg w-[360px] overflow-hidden`}>
        <ModalHeader className={tw`bg-white`} title="Add single payment" onClose={onClose} />
        <AddSinglePaymentForm onSuccess={onSubmit} />
      </div>
    </Modal>
  )
}

export const AddSinglePaymentModal = memo(AddSinglePaymentModalComponent)
