import React, { memo, useCallback, useContext, useMemo, useState } from 'react'
import { PaymentsContext } from '../Payments.context'
import { MenuActionItem } from 'src/interfaces/ui'
import { ActionMenu } from 'src/components/shared/ActionMenu'
import { CancelMinor, ViewMinor } from '@shopify/polaris-icons'
import tw from 'twin.macro'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { useDispatch, useSelector } from 'react-redux'
import { DeletePayment, FetchPaymentInvoice } from 'src/constants/actionTypes'
import { getAccessInfo, getPartnerId } from 'src/selectors/user'
import { showToast } from 'src/utils/toast'
import { PaymentStatus } from 'src/constants/payment/enums'
import { PaymentInvoiceModal } from '../../PaymentInvoiceModal'
import { UserPermission } from 'src/constants/user'
import { handleApiError } from 'src/utils/errorHandlers'

const PaymentsItemActionMenuComponent = () => {
  const dispatch = useDispatch()

  const {
    paymentToAction,
    actionAnchor,
    closePaymentsItemAction,
    clearPaymentsItemAction,
    refreshPayments,
  } = useContext(PaymentsContext)

  const partnerId = useSelector(getPartnerId)
  const accessInfo = useSelector(getAccessInfo)

  const isOpen = Boolean(actionAnchor)

  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [invoice, setInvoice] = useState<any>(null)

  const clearInvoice = useCallback(() => {
    setInvoice(null)
  }, [])

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  const handleDeletePayment = useCallback(async () => {
    if (!paymentToAction || !partnerId) {
      return
    }

    try {
      await createAsyncAction(
        dispatch,
        DeletePayment.request({
          partnerId,
          paymentIds: [paymentToAction.id],
        }),
      )
      showToast('Payment is deleted.')
      refreshPayments()
    } catch (err) {
      handleApiError(err)
    }

    handleCloseConfirm()
  }, [paymentToAction, partnerId])

  const handleDownloadInvoice = useCallback(async () => {}, [invoice])

  const actionItems: MenuActionItem[] = useMemo(() => {
    const res: any = []

    if (paymentToAction?.status === PaymentStatus.Paid) {
      res.push({
        label: 'View invoice',
        icon: <ViewMinor width={20} height={20} />,
        onClick: async () => {
          if (!paymentToAction) {
            return
          }

          const buffer: any = await createAsyncAction(
            dispatch,
            FetchPaymentInvoice.request({
              partnerId,
              paymentId: paymentToAction.id,
            }),
          )

          const blob = new Blob([buffer], { type: 'application/pdf' })

          setInvoice(URL.createObjectURL(blob))

          closePaymentsItemAction()
        },
      })
    }

    if (
      paymentToAction?.status === PaymentStatus.NotPaid &&
      accessInfo?.permission?.includes(UserPermission.PaymentsDelete)
    ) {
      res.push({
        label: 'Delete Payment',
        icon: <CancelMinor width={20} height={20} />,
        onClick: () => {
          if (!paymentToAction) {
            return
          }

          handleOpenConfirm()
          closePaymentsItemAction()
        },
      })
    }

    return res
  }, [paymentToAction, accessInfo])

  return (
    <>
      <ActionMenu
        anchorEl={actionAnchor}
        itemClassName={tw`min-w-[208px]`}
        isOpen={isOpen}
        items={actionItems}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={clearPaymentsItemAction}
      />
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title="Delete payment"
        text="Are you sure you want to delete payment?"
        submitLabel="Delete"
        onClose={handleCloseConfirm}
        onSubmit={handleDeletePayment}
      />
      <PaymentInvoiceModal
        isOpen={Boolean(invoice)}
        invoice={invoice}
        onClose={clearInvoice}
        onDownload={handleDownloadInvoice}
      />
    </>
  )
}

export const PaymentsItemActionMenu = memo(PaymentsItemActionMenuComponent)
