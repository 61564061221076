import React, { memo, useCallback, useContext, useState } from 'react'
import tw from 'twin.macro'
import { ProcessPayments } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'
import { getPartnerId } from 'src/selectors/user'
import { Button } from 'src/components/shared/Button'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentsContext } from './grid/Payments.context'
import { MarkPaidMinor } from '@shopify/polaris-icons'
import { SubmitConfirmModal } from '../shared/SubmitConfirmModal'
import { addS, promiseWithMinimalTime } from 'src/utils/baseUtils'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { handleApiError } from 'src/utils/errorHandlers'

interface Props {
  notPaidPaymentsAmount: number
}

const ProcessPaymentsButtonComponent = ({ notPaidPaymentsAmount }: Props) => {
  const dispatch = useDispatch()

  const partnerId = useSelector(getPartnerId)

  const { refreshPayments } = useContext(PaymentsContext)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!partnerId) {
      return
    }

    handleCloseModal()

    setIsLoading(true)

    try {
      await promiseWithMinimalTime(
        createAsyncAction(
          dispatch,
          ProcessPayments.request({
            partnerId,
          }),
        ),
        2000,
      )

      await refreshPayments()

      showToast('Payments processed successfully!')
    } catch (err: unknown) {
      handleApiError(err)
    }

    setIsLoading(false)
  }, [partnerId, refreshPayments])

  return (
    <>
      <Button
        className={tw`ml-4`}
        size="small"
        color="dark"
        loading={isLoading}
        rightIcon={<MarkPaidMinor width={20} height={20} fill="white" />}
        disabled={!notPaidPaymentsAmount}
        onClick={handleOpenModal}
      >
        Process Payments
      </Button>
      <SubmitConfirmModal
        isOpen={isModalOpen}
        title={`Process ${addS(notPaidPaymentsAmount, 'payment')}`}
        text={
          <Typography variant="h6" color={colors.SHADES_GREY_400}>
            {` Are you sure you want to process ${addS(notPaidPaymentsAmount, 'payment')}?`}
            <div css={tw`flex gap-1`}>
              <Typography variant="h6" color={colors.SHADES_GREY_400}>
                {`All ${notPaidPaymentsAmount} ${addS(notPaidPaymentsAmount, 'payment')} with`}
              </Typography>
              <Typography variant="h6" color={colors.COLORS_BLUE_500}>
                'Not paid'
              </Typography>
              <Typography variant="h6" color={colors.SHADES_GREY_400}>{`status will`}</Typography>
            </div>
            <Typography variant="h6" color={colors.SHADES_GREY_400}>{`process`}</Typography>
          </Typography>
        }
        submitLabel="Process"
        className={tw`w-[320px] min-w-[320px]`}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export const ProcessPaymentsButton = memo(ProcessPaymentsButtonComponent)
