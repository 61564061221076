import React, { memo, useEffect } from 'react'
import { LoadingPage } from '../LoadingPage'
import { useNavigate, useParams } from 'react-router-dom'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { useDispatch } from 'react-redux'
import { FetchInvite } from 'src/constants/actionTypes'
import { ROUTES } from 'src/constants/routes'
import { showToast } from 'src/utils/toast'

const InviteAcceptPageComponent = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchInvite = async () => {
      try {
        if (!params.inviteId) {
          throw 'Invite url is invalid'
        }

        await createAsyncAction(dispatch, FetchInvite.request({ inviteId: params.inviteId }))

        navigate(ROUTES.SIGN_UP_URL)
      } catch (err: any) {
        showToast(err)

        navigate(ROUTES.SIGN_IN_URL)
      }
    }

    fetchInvite()
  }, [])

  return <LoadingPage />
}

export const InviteAcceptPage = memo(InviteAcceptPageComponent)
