import {
  CollectedDataStatus,
  collectedDataStepsTitle,
  CollectedDataStepType,
} from 'src/constants/collectedData'
import { CollectedData } from 'src/interfaces/collectedData'
import { PartnerDriverCollectedData } from 'src/interfaces/onboarding'

export const clientSortCollectedData = (
  collectedData: CollectedData[],
  showWaitingForApprovalStepsFirst?: boolean,
): CollectedData[] => {
  collectedData.sort((a, b) => {
    if (a.collectDataStep.order && b.collectDataStep.order) {
      return a.collectDataStep.order > b.collectDataStep.order ? 1 : -1
    }

    if (a.collectDataStep.type === CollectedDataStepType.InPersonMeeting) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.InPersonMeeting) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.Questionnaire) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.Questionnaire) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.DriverLicense) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.DriverLicense) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.VehicleAndInsurance) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.VehicleAndInsurance) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.InsuranceAmountDeclaration) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.InsuranceAmountDeclaration) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.VehicleRegistration) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.VehicleRegistration) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.VaccinationInformation) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.VaccinationInformation) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.BackgroundCheck) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.BackgroundCheck) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.PaymentMethod) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.PaymentMethod) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.StitchPayment) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.StitchPayment) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.InsuranceBeneficiary) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.InsuranceBeneficiary) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.SignContract) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.SignContract) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.MvrUpload) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.MvrUpload) {
      return 1
    }

    if (a.collectDataStep.type === CollectedDataStepType.BusinessEntity) {
      return -1
    } else if (b.collectDataStep.type === CollectedDataStepType.BusinessEntity) {
      return 1
    }

    return 0
  })

  if (showWaitingForApprovalStepsFirst) {
    collectedData.sort((a, b) => {
      if (a.status === CollectedDataStatus.WaitingForApproval) {
        return -1
      } else if (b.status === CollectedDataStatus.WaitingForApproval) {
        return 1
      }

      return 0
    })
  }

  return collectedData
}

export const getCollectDataStepTitle = (collectedData: CollectedData): string =>
  collectedData?.collectDataStep?.metadata?.title ||
  collectedDataStepsTitle[collectedData.collectDataStep.type]

export const getPartnerDriverCollectDataStepTitle = (
  collectedData: PartnerDriverCollectedData,
): string => collectedData?.metadata?.title || collectedDataStepsTitle[collectedData.type]
