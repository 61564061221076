import { select, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import { getAuthToken, getRefreshToken } from 'src/selectors/auth'
import { Logout, SetAuthTokens } from 'src/constants/actionTypes'
import _ from 'lodash'
import { Endpoints } from 'src/constants/endpoints'

const getBaseUrl = (apiService: ApiService) => {
  if (apiService === ApiService.Paraworks) {
    return process.env.REACT_APP_API_URL
  }

  return process.env.REACT_APP_API_URL
}

interface RefreshTokenPayload {
  accessToken: string
  refreshToken: string
}

export interface Options {
  headers?: {
    [x: string]: string
  }
  method?: string
  body?: any
  query?: string | Record<string, Array<string> | string | number | undefined>
  returnResponse?: boolean
  rewriteUrl?: boolean
  apiService?: ApiService
  params?: Array<UrlReplaceParams>
  resetContentType?: boolean
  mode?: RequestMode
  disableRefreshToken?: boolean
  returnBuffer?: boolean
}

export interface UrlReplaceParams {
  field: string
  value: string
}

export enum ApiService {
  Paraworks = 'paraworks',
}

export interface CallApiResponse<P = any> {
  status: number
  ok: boolean
  payload: P
}

export const callApi = async (
  endpoint: string,
  options: Options = {},
): Promise<CallApiResponse> => {
  const {
    headers,
    method = 'GET',
    body,
    query,
    returnResponse,
    returnBuffer,
    rewriteUrl,
    apiService = ApiService.Paraworks,
    params = [],
    resetContentType,
    mode,
  } = options
  let requestHeaders: { [x: string]: string } = {}
  let requestBody
  const url = rewriteUrl ? '' : getBaseUrl(apiService)

  if (!resetContentType) {
    requestHeaders = {
      ...requestHeaders,
      'Content-Type': 'application/json',
    }
  }

  if (headers) {
    requestHeaders = { ...requestHeaders, ...headers }
  }

  if (body) {
    if (resetContentType) {
      requestBody = body
    } else {
      requestBody = JSON.stringify(body)
    }
  }

  const endpointWithParams = params.reduce(
    (acc, param) => acc.replace(param.field, param.value),
    endpoint,
  )

  let endpointWithQuery = endpointWithParams
  if (query) {
    if (typeof query === 'object') {
      endpointWithQuery += `?${qs.stringify(_.omitBy(query, _.isNil), { encode: false })}`
    } else if (typeof query === 'string') {
      endpointWithQuery += `?${query}`
    }
  }

  const response = await fetch(url + endpointWithQuery, {
    method,
    headers: requestHeaders,
    body: requestBody,
    mode,
  })

  const isOk = response.status >= 200 && response.status < 400

  const responseObject: { status: number; ok: boolean; payload: any } = {
    status: response.status,
    ok: isOk,
    payload: null,
  }

  if (isOk && returnBuffer) {
    responseObject.payload = await response.arrayBuffer()
  } else if (isOk && returnResponse) {
    responseObject.payload = await response.text()
  } else {
    responseObject.payload = await response.json()
  }

  if (!responseObject.ok) {
    throw responseObject
  }

  return responseObject
}

function* refreshToken() {
  const authToken: string | null = yield select(getAuthToken)
  const refreshToken: string | null = yield select(getRefreshToken)

  if (!authToken || !refreshToken) {
    throw new Error('Something went wrong')
  }

  const { payload }: { payload: RefreshTokenPayload } = yield call(
    callApi,
    Endpoints.RefreshToken,
    {
      method: 'POST',
      body: {
        accessToken: authToken,
        refreshToken,
      },
      apiService: ApiService.Paraworks,
    },
  )

  yield put(SetAuthTokens.success(payload))
}

// @ts-ignore
export function* callSecureApi(endpoint: string, options: Options = {}) {
  const authToken: string | null = yield select(getAuthToken)

  if (!authToken) {
    throw new Error('Unauthorized')
  }

  const optionsWithCredentials = {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${authToken}`,
    },
  }

  try {
    // @ts-ignore
    const res = yield call(callApi, endpoint, optionsWithCredentials)

    return res
  } catch (error: any) {
    if (error?.status === 401 && !options.disableRefreshToken) {
      try {
        yield call(refreshToken)
      } catch (err) {
        yield put(Logout.request())

        return
      }

      try {
        // @ts-ignore
        const newRes = yield callSecureApi(endpoint, { ...options, disableRefreshToken: true })

        return newRes
      } catch (err: any) {
        if (err?.status === 401) {
          yield put(Logout.request())

          return
        }

        throw err
      }
    }

    throw error
  }
}
