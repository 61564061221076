import React, { memo } from 'react'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { CancelSmallMinor } from '@shopify/polaris-icons'

interface Props {
  title: string
  className?: any
  onClose(): void
}

const ModalHeaderComponent = ({ title, className, onClose }: Props) => {
  return (
    <div
      css={[
        tw`flex flex-row justify-between items-center p-2 pl-4 border-0 border-b border-solid  border-[#EDEDED]`,
        className,
      ]}
    >
      <Typography variant="h5">{title}</Typography>
      <div
        css={tw`p-1 rounded bg-[#EDEDED] h-[28px] hover:bg-[#DAD9E0] active:bg-[#B4AFC0] cursor-pointer`}
        onClick={onClose}
      >
        <CancelSmallMinor width={20} height={20} />
      </div>
    </div>
  )
}

export const ModalHeader = memo(ModalHeaderComponent)
