import { Logout, SetActivityDetails, SetDashboardsUpdates } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ActivitiesState } from './types'
import { DashboardsUpdatesType } from 'src/constants/activities'
import { activitiesService } from 'src/services/ActivitiesService'

const getInitialState = (): ActivitiesState => ({
  activityDetails: null,
  updates: {
    [DashboardsUpdatesType.DisabledContractors]: {
      viewedIds: activitiesService.getViewedUpdates(DashboardsUpdatesType.DisabledContractors),
      currentIds: [],
    },
    [DashboardsUpdatesType.FailedPayments]: {
      viewedIds: activitiesService.getViewedUpdates(DashboardsUpdatesType.FailedPayments),
      currentIds: [],
    },
    [DashboardsUpdatesType.WaitingForApproval]: {
      viewedIds: activitiesService.getViewedUpdates(DashboardsUpdatesType.WaitingForApproval),
      currentIds: [],
    },
  },
})

export const activitiesReducer = (
  state: ActivitiesState = getInitialState(),
  action: Action<any>,
): ActivitiesState => {
  switch (action.type) {
    case SetActivityDetails.type.SUCCESS:
      return {
        ...state,
        activityDetails: action.payload,
      }
    case SetDashboardsUpdates.type.SUCCESS: {
      const type = action.payload.type as DashboardsUpdatesType

      return {
        ...state,
        updates: {
          ...state.updates,
          [type]: { ...state.updates[type], ...action.payload.updatesItem },
        },
      }
    }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
