import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { ActionButton } from '../shared/ActionButton'
import { ActionMenu } from '../shared/ActionMenu'
import { MenuActionItem } from 'src/interfaces/ui'
import { useDispatch } from 'react-redux'
import { Logout } from 'src/constants/actionTypes'
import { LogOutMinor, ProfileMinor } from '@shopify/polaris-icons'

const PartnersProfileButtonComponent = () => {
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const actionItems: Array<MenuActionItem> = useMemo(() => {
    return [
      {
        label: 'Log out',
        icon: <LogOutMinor width={20} />,
        onClick: () => {
          dispatch(Logout.request(null))
          setAnchorEl(null)
        },
      },
    ]
  }, [])

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <ActionButton
        selected={!!anchorEl}
        className={tw`px-4`}
        color="dark"
        text="Customer's Account"
        icon={<ProfileMinor fill="white" width={20} />}
        onClick={handleClick}
      />
      <ActionMenu
        anchorEl={anchorEl}
        items={actionItems}
        isOpen={isMenuOpen}
        itemClassName={tw`min-w-[180px]`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseMenu}
      />
    </>
  )
}

export const PartnersProfileButton = memo(PartnersProfileButtonComponent)
