import { fork } from '@redux-saga/core/effects'
import { onboardingSharedSaga } from './shared'
import { onboardingInviteWorkersSaga } from './inviteWorkers'
import { onboardingActionsSaga } from './actions'
import { onboardingManageDriversSaga } from './manageDrivers'

export function* onboardingSaga() {
  yield fork(onboardingSharedSaga)
  yield fork(onboardingInviteWorkersSaga)
  yield fork(onboardingActionsSaga)
  yield fork(onboardingManageDriversSaga)
}
