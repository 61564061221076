import * as Yup from 'yup'
import YupPassword from 'yup-password'

YupPassword(Yup)

export const SignUpSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Must contain at least 8 characters')
    .minLowercase(1, 'Must contain at least 1 lower case letter')
    .minUppercase(1, 'Must contain at least 1 upper case letter')
    .minNumbers(1, 'Must contain at least 1 number'),
  confirmPassword: Yup.string()
    .required('Repeat password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
})
