import { Logout, FetchTags } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { TagState } from './types'

const getInitialState = (): TagState => ({
  tags: [],
  isTagsInitialized: false,
})

export const tagReducer = (state: TagState = getInitialState(), action: Action<any>): TagState => {
  switch (action.type) {
    case FetchTags.type.SUCCESS:
      return {
        ...state,
        isTagsInitialized: true,
        tags: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
