import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import { Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import { Button } from './Button'
import { showToast } from 'src/utils/toast'
import { ModalHeader } from './ModalHeader'
import { RiskMajor } from '@shopify/polaris-icons'

interface Props {
  isOpen?: boolean
  title?: string
  text?: ReactNode
  submitLabel: string
  className?: any
  onSubmit(): void | Promise<void>
  onClose(): void
}

const WarningModalComponent = ({
  isOpen,
  title,
  text,
  submitLabel,
  className,
  onSubmit,
  onClose,
}: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)

    try {
      await onSubmit()
    } catch (err) {
      showToast(err as any, { variant: 'error' })
    }

    setIsLoading(false)
  }, [onSubmit])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur p-4`}
    >
      <div css={[tw`w-[360px] flex flex-col bg-white rounded-lg`, className]}>
        <ModalHeader title={title || 'Please confirm'} onClose={onClose} />
        <div
          css={tw`w-16 h-16 rounded-[32px] flex justify-center items-center bg-[#FFE8EE] mt-4 self-center`}
        >
          <RiskMajor fill={colors.COLORS_RED_500} width={20} />
        </div>
        {text && (
          <Typography css={tw`m-4 text-center`} variant="h6" color={colors.SHADES_GREY_400}>
            {text}
          </Typography>
        )}
        <div
          css={tw`flex justify-between items-center px-4 py-3 border-0 border-t border-solid  border-[#EDEDED]`}
        >
          <Button className={tw`px-8`} disabled={isLoading} onClick={onClose} size="small">
            Cancel
          </Button>
          <Button
            className={tw`px-8`}
            loading={isLoading}
            onClick={handleSubmit}
            color="light"
            size="small"
          >
            {submitLabel}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const WarningModal = memo(WarningModalComponent)
