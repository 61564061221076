import { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { SubmitConfirmModal } from 'src/components/shared/SubmitConfirmModal'

interface Props {
  onActivate(): void
}

const ActivateContractorButtonComponent = ({ onActivate }: Props) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  return (
    <div css={tw`w-full bg-white px-4 py-2`}>
      <Button className={tw`px-8`} size="small" color="green" onClick={handleOpenConfirm}>
        Activate contractor
      </Button>
      <SubmitConfirmModal
        isOpen={isConfirmOpen}
        title="Activate contractor?"
        text="Are you sure you want to activate contractor?"
        submitLabel="Activate"
        onClose={handleCloseConfirm}
        onSubmit={onActivate}
      />
    </div>
  )
}

export const ActivateContractorButton = memo(ActivateContractorButtonComponent)
