import { DashboardsUpdatesType } from 'src/constants/activities'
import { ActivityItem, DashboardsUpdatesItem } from 'src/interfaces/activities'
import { State } from 'src/reducers/types'

export const getActivityDetails = (state: State): ActivityItem | null =>
  state.activities.activityDetails

export const getDashboardsUpdates =
  (type: DashboardsUpdatesType) =>
  (state: State): DashboardsUpdatesItem =>
    state.activities.updates[type]

// export const getDashboardsUpdatesNotViewed =
//   (type: DashboardsUpdatesType) =>
//   (state: State): string[] =>
//     state.activities.updates[type].currentIds.filter(
//       (item) => !state.activities.updates[type].viewedIds.some((viewed) => viewed === item),
//     )

export const getDashboardsUpdatesNotViewed =
  (type: DashboardsUpdatesType) =>
  (state: State): string[] =>
    state.activities.updates[type].currentIds
