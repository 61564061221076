import { GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid-pro'
import React, { memo, useEffect, useState } from 'react'
import { GridTable } from 'src/components/shared/grid/GridTable'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { onboardingColumns } from 'src/constants/onboarding/onboardingGrid'
import { Onboarding } from 'src/interfaces/onboarding'
import tw from 'twin.macro'
import { EmptyOnboardings } from './EmptyOnboardings'
import { DropdownMinor } from '@shopify/polaris-icons'
import { ExportTableButton } from 'src/components/shared/grid/ExportTableButton'

const handleGetRowId = (row: Onboarding) => row.id

interface Props {
  onboardings: Array<Onboarding>
  selectedOnboardingStatus: OnboardingStatus
  isLoading: boolean
  paginationModel: GridPaginationModel
  pageSizeOptions: Array<number>
  rowCount: number
  rowSelectionModel: GridRowSelectionModel
  DataGridTitle: any
  initialSortField?: string
  onPaginationModelChange(paginationModel: GridPaginationModel): void
  onRowSelectionModelChange(rowSelectionModel: GridRowSelectionModel): void
  onRowClick(params: { row: Onboarding }): void
}

const OnboardingsGridComponent = ({
  onboardings,
  isLoading,
  // paginationModel,
  // pageSizeOptions,
  rowCount,
  rowSelectionModel,
  selectedOnboardingStatus,
  DataGridTitle,
  initialSortField,
  // onPaginationModelChange,
  onRowClick,
  onRowSelectionModelChange,
}: Props) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: initialSortField || 'createdAt', sort: 'desc' },
  ])

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel)
  }

  useEffect(() => {
    setSortModel([{ field: initialSortField || 'createdAt', sort: 'desc' }])
  }, [initialSortField])

  return (
    <div
      css={[
        tw`w-full mt-4 h-[400px] rounded-lg`,
        {
          '@media (min-height: 830px)': tw`h-[500px]`,
          '@media (min-height: 1000px)': tw`h-[700px]`,
          '@media (min-height: 1440px)': tw`h-[1100px]`,
          '@media (min-height: 2000px)': tw`h-[1500px]`,
        },
      ]}
    >
      <GridTable
        rows={onboardings}
        columns={onboardingColumns[selectedOnboardingStatus]}
        rowSelectionModel={rowSelectionModel}
        loading={isLoading}
        // pagination
        // paginationMode="server"
        // paginationModel={paginationModel}
        rowCount={rowCount}
        // onPaginationModelChange={onPaginationModelChange}
        // pageSizeOptions={pageSizeOptions}
        getRowId={handleGetRowId}
        onRowClick={onRowClick}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowHeight={44}
        columnHeaderHeight={36}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        slots={{
          toolbar: DataGridTitle,
          noRowsOverlay: EmptyOnboardings,
          noResultsOverlay: EmptyOnboardings,
          columnSortedDescendingIcon: () => <DropdownMinor fill="#655E7C" width={20} />,
          columnSortedAscendingIcon: () => (
            <DropdownMinor fill="#655E7C" css={tw`rotate-180`} width={20} />
          ),
          columnUnsortedIcon: () => null,
          footer: () => (
            <div
              css={tw`py-2 px-4 flex justify-end w-full bg-white border-0 border-t border-solid border-[#EDEDED]`}
            >
              <ExportTableButton />
            </div>
          ),
        }}
        css={tw`bg-white border-0`}
      />
    </div>
  )
}

export const OnboardingsGrid = memo(OnboardingsGridComponent)
