import React, { memo, useCallback, useMemo, useState } from 'react'
import { Modal } from '@mui/material'
import tw from 'twin.macro'
import { ModalHeader } from 'src/components/shared/ModalHeader'
import { SetActivityDetails, UploadPaymentsCSV } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'
import { getPartnerId } from 'src/selectors/user'
import { UploadedFile } from 'src/components/shared/UploadedFile'
import { FileUploadInput } from 'src/components/shared/FileUploadInput'
import { Button } from 'src/components/shared/Button'
import { useDispatch, useSelector } from 'react-redux'
import { handleApiError } from 'src/utils/errorHandlers'
import { processPaymentCsvErrors } from 'src/utils/payments'
import { activitiesService } from 'src/services/ActivitiesService'
import { useSnackbar } from 'notistack'

interface Props {
  isOpen?: boolean
  onClose: () => void
  onSubmit: () => void
}

const ACCESS_TYPES = ['text/csv']

const UploadPaymentsCSVModalComponent = ({ isOpen, onClose, onSubmit }: Props) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const partnerId = useSelector(getPartnerId)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [file, setFile] = useState<File | null>(null)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleSubmit = async () => {
    if (!partnerId) {
      return
    }

    setIsLoading(true)

    try {
      await createAsyncAction(dispatch, UploadPaymentsCSV.request({ file, partnerId }))

      showToast('File uploaded!')

      onSubmit()
    } catch (err: any) {
      if (Array.isArray(err)) {
        const processedPaymentCsvErrors = processPaymentCsvErrors(err)

        const activity = activitiesService.addActivity({
          title: 'File upload error',
          text: `Unable to proceed due to ${processedPaymentCsvErrors.length} errors (${file?.name})`,
          content: processedPaymentCsvErrors,
        })

        enqueueSnackbar(activity.title, {
          variant: 'errorWithDetails',
          detailsPayload: activity,
          onDetailsClick: (activity) => {
            dispatch(SetActivityDetails.success(activity))
          },
        })
      } else {
        handleApiError(err)
      }
    } finally {
      setFile(null)
      onClose()
      setIsLoading(false)
    }
  }

  const handleUploadFile = useCallback(async (file: File) => {
    setFile(file)
  }, [])

  const handleRemoveFile = useCallback(() => {
    setFile(null)
  }, [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur`}
    >
      <div css={tw`bg-[#F6F6F6] rounded-lg w-[360px] overflow-hidden`}>
        <ModalHeader className={tw`bg-white`} title="Upload CSV" onClose={onClose} />
        <div>
          <div css={tw`bg-white rounded-xl m-4`}>
            {file ? (
              <UploadedFile file={file} onRemove={handleRemoveFile} />
            ) : (
              <FileUploadInput
                // 10 MB
                fileSizeLimit={10000000}
                acceptTypes={ACCESS_TYPES}
                onSelect={handleUploadFile}
              />
            )}
          </div>
        </div>
        <div
          css={tw`flex flex-row justify-between items-end  border-0 border-t border-solid bg-white border-[#EDEDED] mt-4 p-4 pt-3`}
        >
          <Button
            variant="contained"
            loading={isLoading}
            size="small"
            color="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
          <div css={tw`flex flex-col items-end`}>
            <Button
              variant="contained"
              size="small"
              loading={isLoading}
              disabled={!file}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const UploadPaymentsCSVModal = memo(UploadPaymentsCSVModalComponent)
