import { showToast } from './toast'

export const handleApiError = (err: any) => {
  if (typeof err === 'object') {
    err.slice(0, 3).forEach((item: any) => {
      const message = typeof item === 'object' ? 'Something went wrong' : item

      showToast(message, {
        variant: 'error',
        autoHideDuration: 6000,
      })
    })
  } else {
    showToast(err, {
      variant: 'error',
      autoHideDuration: 6000,
    })
  }
}
