import { Logout, FetchMarkets, SetSelectedMarket } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { MarketState } from './types'

const getInitialState = (): MarketState => ({
  markets: [],
  selectedMarket: null,
  isMarketsInitialized: false,
})

export const marketReducer = (
  state: MarketState = getInitialState(),
  action: Action<any>,
): MarketState => {
  switch (action.type) {
    case FetchMarkets.type.SUCCESS:
      return {
        ...state,
        isMarketsInitialized: true,
        markets: action.payload,
      }
    case SetSelectedMarket.type.SUCCESS:
      return {
        ...state,
        selectedMarket: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
