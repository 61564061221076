import * as Yup from 'yup'

export const InviteWorkersViaMessageSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone number is required'),
  email: Yup.string().required('Email is required'),
  collectDataTemplateId: Yup.string().required('Onboarding template is required'),
  partnerMarketId: Yup.string().required('Market is required'),
})
