import dayjs from 'dayjs'
import { DashboardsUpdatesType } from 'src/constants/activities'
import { ActivityItem, ActivityItemPayload } from 'src/interfaces/activities'
import { v4 } from 'uuid'

class ActivitiesService {
  private activitiesStorageKey = 'activities'

  public getActivities(): ActivityItem[] {
    const activitiesString = localStorage.getItem(this.activitiesStorageKey)

    const activities: ActivityItem[] = activitiesString ? JSON.parse(activitiesString) : []

    const validActivities = activities.filter((activity) =>
      dayjs(activity.expiresAt).isAfter(dayjs()),
    )

    localStorage.setItem(this.activitiesStorageKey, JSON.stringify(validActivities))

    return validActivities.sort((a, b) => b.createdAt - a.createdAt)
  }

  public addActivity(activity: ActivityItemPayload): ActivityItem {
    const activities = this.getActivities()

    const newActivity = {
      ...activity,
      id: v4(),
      date: new Date().toISOString(),
      createdAt: Date.now(),
      expiresAt: this.getActivityExpirationDate(),
    }

    activities.push(newActivity)

    localStorage.setItem(this.activitiesStorageKey, JSON.stringify(activities))

    return newActivity
  }

  public getViewedUpdates = (type: DashboardsUpdatesType): string[] => {
    const viewedUpdatesString = localStorage.getItem(this.getViewedUpdatesStorageKey(type))

    return viewedUpdatesString ? JSON.parse(viewedUpdatesString) : []
  }

  public setViewedUpdates = (type: DashboardsUpdatesType, items: string[]): string[] => {
    // let viewedUpdates = this.getViewedUpdates(type)

    // viewedUpdates = [...viewedUpdates.concat(items)]

    localStorage.setItem(this.getViewedUpdatesStorageKey(type), JSON.stringify(items))

    return items
  }

  private getViewedUpdatesStorageKey = (type: DashboardsUpdatesType) =>
    `viewed_${this.activitiesStorageKey}_${type}`

  private getActivityExpirationDate(): number {
    const todaysExpiration = dayjs().startOf('day').add(3, 'hours')
    const now = dayjs()

    if (now.isBefore(todaysExpiration)) {
      return todaysExpiration.valueOf()
    }

    return todaysExpiration.add(1, 'day').valueOf()
  }
}

export const activitiesService = new ActivitiesService()
