const BASE_URL = '/'

const INVITE_ACCEPT_URL = `${BASE_URL}invitation/:inviteId/accept`
const SIGN_UP_URL = `${BASE_URL}sign-up`
const SIGN_IN_URL = `${BASE_URL}sign-in`
const SIGN_IN_AS_URL = `${BASE_URL}sign-in-as`
const FORGOT_PASSWORD_URL = `${BASE_URL}forgot-password`
const FORGOT_PASSWORD_LINK_SENT_URL = `${BASE_URL}forgot-password-sent`
const RESET_PASSWORD_URL = `${BASE_URL}password-reset`

const ONBOARDING_URL = `${BASE_URL}onboarding`
const PAYMENTS_URL = `${BASE_URL}payments`
const MANAGERS_URL = `${BASE_URL}managers`
const CONTRACTORS_URL = `${BASE_URL}contractors`

const SUCCESS_URL = `${BASE_URL}success`
const NOT_FOUND_URL = `${BASE_URL}not-found`
const LOGGED_OUT_URL = `${BASE_URL}logged-out`

export const ROUTES = {
  BASE_URL,

  INVITE_ACCEPT_URL,
  SIGN_UP_URL,
  SIGN_IN_URL,
  SIGN_IN_AS_URL,
  FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_LINK_SENT_URL,
  RESET_PASSWORD_URL,

  ONBOARDING_URL,
  PAYMENTS_URL,
  MANAGERS_URL,
  CONTRACTORS_URL,

  SUCCESS_URL,
  NOT_FOUND_URL,
  LOGGED_OUT_URL,
}
