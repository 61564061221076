import { memo, useMemo } from 'react'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { CircleCancelMajor } from '@shopify/polaris-icons'
import { DataRow } from 'src/components/shared/DataRow'
import { ContractorDetails } from 'src/interfaces/contractor'
import { DateTime } from 'luxon'
import { StepsChip } from '../onboarding/grid/StepsChip'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { getCollectDataStepTitle } from 'src/utils/collectedData'

interface Props {
  contractorDetails: ContractorDetails
}

const ContractorFailedStepComponent = ({ contractorDetails }: Props) => {
  const failedAt = useMemo(
    () =>
      contractorDetails?.collectedData.find((item) => item.status === CollectedDataStatus.Failed)
        ?.updatedAt,
    [contractorDetails],
  )

  return (
    <div css={tw`bg-white rounded-lg mb-6`}>
      <div css={tw`flex flex-col justify-between`}>
        <div css={tw`flex items-center py-3 px-4`}>
          <CircleCancelMajor width={20} />
          <Typography css={tw`ml-3`} variant="subtitle1">
            Onboarding failed
          </Typography>
        </div>
        <DataRow
          title="Failed on"
          text={failedAt ? DateTime.fromMillis(failedAt * 1000).toFormat('LLL dd, yyyy') : '-'}
        />
        <DataRow
          title="Failed on stage"
          component={
            <StepsChip
              titles={contractorDetails.collectedData
                .filter((item) => item.status === CollectedDataStatus.Failed)
                .map((item) => getCollectDataStepTitle(item))}
            />
          }
        />
      </div>
    </div>
  )
}

export const ContractorFailedStep = memo(ContractorFailedStepComponent)
