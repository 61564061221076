import React, { memo } from 'react'
import tw from 'twin.macro'
import { ProductsMinor } from '@shopify/polaris-icons'
import { Typography } from '@mui/material'
import { cutLongWord } from 'src/utils/baseUtils'

interface Props {
  tag: string
}

const TableTagComponent = ({ tag }: Props) => {
  if (!tag) {
    return null
  }

  return (
    <div css={tw`bg-[#EDEDED] rounded flex items-center pl-1 pr-2`}>
      <ProductsMinor width={16} css={tw`m-0.5`} />
      <Typography variant="subtitle1">{cutLongWord(tag, 10)}</Typography>
    </div>
  )
}

export const TableTag = memo(TableTagComponent)
