import { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'

interface Props {
  onDeactivate(): void
}

const DeactivateContractorButtonComponent = ({ onDeactivate }: Props) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  return (
    <div css={tw`w-full bg-white px-4 py-2`}>
      <Button className={tw`px-8`} size="small" color="red" onClick={handleOpenConfirm}>
        Deactivate contractor
      </Button>
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title="Deactivate contractor?"
        text="Are you sure you want to reject contractor?"
        submitLabel="Deactivate"
        onClose={handleCloseConfirm}
        onSubmit={onDeactivate}
      />
    </div>
  )
}

export const DeactivateContractorButton = memo(DeactivateContractorButtonComponent)
