import { Formik } from 'formik'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SignUp } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { SignUpFormValues } from './SignUpForm.interface'
import { SignUpSchema } from './SignUpForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { Invite } from 'src/interfaces/invite'
import { handleApiError } from 'src/utils/errorHandlers'

const initialValues: SignUpFormValues = {
  password: '',
  confirmPassword: '',
}

interface Props {
  invite: Invite
}

export const SignUpForm = ({ invite }: Props) => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFormSubmit = async (values: SignUpFormValues) => {
    setIsLoading(true)

    try {
      const { email, firstName, lastName, id } = invite

      await createAsyncAction(
        dispatch,
        SignUp.request({ email, firstName, lastName, inviteId: id, ...values }),
      )
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={SignUpSchema}
    >
      {({ handleSubmit }) => (
        <div css={tw`w-full`}>
          <FormikTextField
            name="password"
            type="password"
            label="Create password"
            css={tw`w-full mb-6`}
            placeholder="Enter new password"
          />
          <FormikTextField
            name="confirmPassword"
            type="password"
            label="Repeat password"
            css={tw`w-full mb-6`}
            placeholder="Repeat new password"
          />
          <Button
            className={tw`w-full`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            Create password
          </Button>
        </div>
      )}
    </Formik>
  )
}
