import { Formik, FormikProps } from 'formik'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AddSinglePaymentFormValues } from './AddSinglePaymentForm.interface'
import { AddSinglePaymentSchema } from './AddSinglePaymentForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { handleApiError } from 'src/utils/errorHandlers'
import { FormikAutocomplete } from 'src/components/shared/form/formik/FormikAutocomplete'
import { Contractor } from 'src/interfaces/contractor'
import { debounce } from '@mui/material/utils'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  AddSinglePayment,
  FetchContractors,
  ValidateSinglePayment,
} from 'src/constants/actionTypes'
import { ContractorState } from 'src/constants/contractor/enums'
import { showToast } from 'src/utils/toast'
import { SinglePaymentInvalidReason } from 'src/constants/payment/enums'
import { WarningModal } from 'src/components/shared/WarningModal'
import { SearchMinor } from '@shopify/polaris-icons'
import { WarningContainer } from 'src/components/shared/WarningContainer'

interface Props {
  onSuccess(): void
}

const initialValues: AddSinglePaymentFormValues = {
  contractorId: '',
  amount: '',
}

const AMOUNT_MASK: any = (rawValue: string) => {
  const numbers = rawValue.replace('$ ', '').replace('.', '')

  const str = numbers.padStart(3, '0')

  const res = str.slice(0, -2) + '.' + str.slice(-2)

  return ['$', ' ', ...res.split('')].map((item) => (item.match(/\d/) ? /\d/ : item))
}

export const AddSinglePaymentForm = ({ onSuccess }: Props) => {
  const dispatch = useDispatch()

  const formikRef = useRef<FormikProps<AddSinglePaymentFormValues>>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedContractor, setSelectedContractor] = useState<Contractor | null>(null)
  const [contractors, setContractors] = useState<Array<Contractor>>([])
  const [isHasUnprocessedModalOpen, setIsHasUnprocessedModalOpen] = useState<boolean>(false)
  const [isHasAlreadyProcessedModalOpen, setIsHasAlreadyProcessedModalOpen] =
    useState<boolean>(false)

  const [amount, setAmount] = useState<number>(0)
  const [contractorId, setContractorId] = useState<string>('')

  const optionsList = useMemo(() => {
    const res: Contractor[] = []

    if (selectedContractor && !contractors.some((item) => item.id === selectedContractor.id)) {
      res.push(selectedContractor)
    }

    res.push(...contractors)

    return res.map((item) => ({
      label: item.name,
      subLabel: item.externalId,
      value: item.id,
      contractor: item,
    }))
  }, [selectedContractor, contractors])

  const fetchContractors = useMemo(
    () =>
      debounce(async (searchQuery?: string) => {
        const params: any = {
          paymentsAvailableOnly: true,
          contractorState: ContractorState.Active,
        }

        if (searchQuery) {
          params.contractorSearch = searchQuery
        }

        const newContractors: Array<Contractor> = await createAsyncAction(
          dispatch,
          FetchContractors.request(params),
        )

        setContractors(newContractors)
      }, 300),
    [],
  )

  useEffect(() => {
    if (!inputValue) {
      setContractors([])

      return
    }

    fetchContractors(inputValue)
  }, [inputValue])

  const createPayment = useCallback(
    async ({ amount, partnerDriverId }: { partnerDriverId: string; amount: number }) => {
      try {
        await createAsyncAction(
          dispatch,
          AddSinglePayment.request({
            amount,
            partnerDriverId,
          }),
        )

        showToast('Payment is created!')
        onSuccess()
      } catch (err: any) {
        handleApiError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [],
  )

  const createPaymentFromLocalState = useCallback(async () => {
    try {
      await createPayment({ amount, partnerDriverId: contractorId })

      setAmount(0)
      setContractorId('')
    } catch {
      //
    }
  }, [amount, contractorId, createPayment])

  const handleFormSubmit = async (values: AddSinglePaymentFormValues) => {
    setIsLoading(true)

    const resAmount = +values.amount.replace('$ ', '').replace('.', '')

    try {
      const { isValid, reason }: { isValid: boolean; reason?: SinglePaymentInvalidReason } =
        await createAsyncAction(
          dispatch,
          ValidateSinglePayment.request({
            amount: resAmount,
            partnerDriverId: values.contractorId,
          }),
        )

      if (isValid) {
        await createPayment({ amount: resAmount, partnerDriverId: values.contractorId })

        return
      }

      if (!reason) {
        return
      }

      setAmount(resAmount)
      setContractorId(values.contractorId)

      if (reason === SinglePaymentInvalidReason.HasAlreadyProcessed) {
        setIsHasAlreadyProcessedModalOpen(true)

        return
      }

      if (reason === SinglePaymentInvalidReason.HasUnprocessed) {
        setIsHasUnprocessedModalOpen(true)

        return
      }
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = useCallback((event: any, newInputValue: string) => {
    setInputValue(newInputValue)
  }, [])

  const closeHasAlreadyProcessedModal = useCallback(async () => {
    setIsHasAlreadyProcessedModalOpen(false)
  }, [])

  const closeHasUnprocessedModal = useCallback(async () => {
    setIsHasUnprocessedModalOpen(false)
  }, [])

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={AddSinglePaymentSchema}
        innerRef={formikRef}
      >
        {({ handleSubmit, setFieldValue }) => (
          <div css={tw`w-full bg-white`}>
            <div css={tw`p-4`}>
              <FormikAutocomplete
                name="contractorId"
                label="Select contractor"
                placeholder="Start typing name"
                noOptionsText="No contractors"
                options={optionsList}
                filterOptions={(x) => x}
                onInputChange={handleInputChange}
                onChange={(value) => {
                  setSelectedContractor(
                    optionsList.find((item) => item.contractor.id === value)?.contractor || null,
                  )
                  setFieldValue('contractorId', value)
                }}
                startIcon={<SearchMinor width={20} />}
              />
              <div css={tw`w-full mt-4`}>
                <FormikTextField
                  name="amount"
                  label="Enter amount"
                  css={tw`w-full`}
                  mask={AMOUNT_MASK}
                  placeholder="$ 00.00"
                />
              </div>
              <WarningContainer
                title="Please ensure you enter the amount in the correct format: $0.00. Incorrect formats may cause errors in processing."
                subtitle="For example, $10.50 or $5.25."
                className="mt-4"
              />
            </div>
            <div
              css={tw`flex justify-end px-4 py-3 border-0 border-t border-solid border-[#EDEDED]`}
            >
              <Button
                className={tw`w-[146px]`}
                variant="contained"
                size="small"
                loading={isLoading}
                onClick={() => handleSubmit()}
              >
                Add payment
              </Button>
            </div>
          </div>
        )}
      </Formik>
      <WarningModal
        isOpen={isHasAlreadyProcessedModalOpen}
        title="Add single payment"
        submitLabel="Add payment"
        text="This contractor already has payment processed recently, are you sure you want to create another payment for them?"
        onSubmit={createPaymentFromLocalState}
        onClose={closeHasAlreadyProcessedModal}
      />
      <WarningModal
        isOpen={isHasUnprocessedModalOpen}
        title="Add single payment"
        submitLabel="Add payment"
        text="This contractor already has an unprocessed payment created, are you sure you want to create another payment for them?"
        onSubmit={createPaymentFromLocalState}
        onClose={closeHasUnprocessedModal}
      />
    </>
  )
}
